import React, { useState, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import logo1 from '../images/favicon-32x32.png'
import Loader from './Loader';
import toggleIcon from '../images/t-btn1.svg';
import toggleIcon2 from '../images/t-btn1 (1).svg';

const Navbar = () => {

    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);

    const handleLogout = () => {
        localStorage.removeItem('token');
        window.location.href = "logout";
    }

    useEffect(() => {
        if (!localStorage.getItem("token")) {
            navigate("/login");
        }
        else {
            verifyUser();
        }

        // eslint-disable-next-line
    }, [])

    const checkRole = (id) => {
        if (id === "3") {
            setTimeout(() => {
                navigate('/form')
            }, 1000);
        }
        else {
            setTimeout(() => {
                setLoading(false);
            }, 1000);
        }
    }

    const verifyUser = async () => {
        setLoading(true); // Set loading to true when fetching starts

        const token = localStorage.getItem("token");

        try {
            const response = await fetch(`/auth/get_user_data`, {
                headers: {
                    "Authorization": localStorage.getItem("token")
                }
            });
            const data = await response.json();
            console.log("auth_data", data);
            if (data.suc) {
                console.log("Authentication completed");
                checkRole(data.auth.role_id);
            } else {
                localStorage.removeItem('token');
                navigate("/login");
            }
        } catch (error) {
            console.error('Error fetching user data:', error);
            // localStorage.removeItem('token');
            // navigate("/login");
            alert('failed in catch');
        }
    }

    const [isFull, setIsFull] = useState(false);

    useEffect(() => {
        setIsFull(false);
    }, [])


    const handleClick = () => {
        setIsFull(!isFull);
    };

    const handleMouseEnter = () => {
        setIsFull(true);
    }

    const handleMouseLeave = () => {
        setIsFull(false);
    }


    return (
        <div className='toggle-navbar' style={{ position: "relative" }}>
            {/* {loading && (
                <div className="loading-overlay">
                    <Loader />
                </div>
            )} */}

            <div className={`nav-button ${isFull && 'open'}`} onClick={handleClick}>
                {isFull ? <img src={toggleIcon} alt="" /> : <img src={toggleIcon2} alt="" />}
            </div>

            <nav className={`navbar d-none d-lg-block show navbar-vertical navbar-expand px-0 py-3 pt-0 navbar-light border-bottom border-bottom-0 border-end-lg pb-0 ${isFull ? 'navbar-wide' : 'navbar-narrow'}`} id="navbarVertical" style={{height:"100%"}}>
                <div className="container-fluid">

                    <Link className="navbar-brand py-2 mb-lg-0 me-0 text-center customDesign" to="/">
                        {!isFull ? <img src='https://cresttechnosoft.com/wp-content/uploads/2018/05/favicon.png' alt="..." /> : <img src='https://cresttechnosoft.com/wp-content/uploads/2024/08/logo-3.svg' alt="..." />}
                    </Link>

                    <div className="collapse1 navbar-collapse" id="sidebarCollapse">
                        <ul className="navbar-nav">
                            <li className="nav-item mt-0">
                                <Link className="nav-link active" title='Home' to="/">
                                    <i className="fa-solid fa-house"></i>
                                    {isFull && <p>Home</p>}
                                </Link>
                            </li>
                            <li className="nav-item mt-0">
                                <Link className="nav-link active" title='Role Manager' to="/role">
                                    <i className="fa-regular fa-user"></i>
                                    {isFull && <p>Role Manager</p>}
                                </Link>
                            </li>
                            <li className="nav-item mt-0">
                                <Link className="nav-link active" title='Add User' to="/add_user">
                                    <i className="fa-solid fa-user-plus"></i>
                                    {isFull && <p>Add User</p>}
                                </Link>
                            </li>
                            <li className="nav-item mt-0">
                                <button className="nav-link active" onClick={handleLogout} title='logout' to="/">
                                    <i className="fa-solid fa-arrow-right-from-bracket text-start"></i>
                                    {isFull && <p>Logout</p>}
                                </button>
                            </li>
                        </ul>

                        {/* <hr className="navbar-divider my-5 opacity-20" /> */}

                        {/* <div className="mt-auto"></div>

                            <ul className="navbar-nav d-none d-lg-block">
                                <li className="nav-item">
                                    <Link className="nav-link" to="/profile">
                                        <i className="bi bi-person-square"></i> Change Password
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <button className="my-btng nav-link" onClick={handleLogout}>
                                        <i className="bi bi-box-arrow-left"></i> Logout
                                    </button>
                                </li>
                            </ul> */}
                    </div>
                </div>
            </nav>

            <nav className={`navbar d-block d-lg-none position-fixed h show navbar-vertical h-screen navbar-expand px-0 py-3 pt-0 navbar-light border-bottom border-bottom-0 border-end-lg pb-0 ${isFull ? 'navbar-wide1' : 'navbar-narrow2'}`} id="navbarVertical">
                <div className="container-fluid">


                    <Link className="navbar-brand py-2 mb-lg-0 me-0 text-center customDesign" to="/">
                        <img src='https://cresttechnosoft.com/wp-content/uploads/2024/08/logo-3.svg' alt="..." />
                    </Link>

                    <div className="collapse1 navbar-collapse" id="sidebarCollapse">
                        <ul className="navbar-nav">
                            <li className="nav-item mt-0">
                                <Link className="nav-link active" title='Home' to="/">
                                    <i className="fa-solid fa-house"></i>
                                    <p>Home</p>
                                </Link>
                            </li>
                            <li className="nav-item mt-0">
                                <Link className="nav-link active" title='Role Manager' to="/role">
                                    <i className="fa-regular fa-user"></i>
                                    <p>Role Manager</p>
                                </Link>
                            </li>
                            <li className="nav-item mt-0">
                                <Link className="nav-link active" title='Add User' to="/add_user">
                                    <i className="fa-solid fa-user-plus"></i>
                                    <p>Add User</p>
                                </Link>
                            </li>
                            <li className="nav-item mt-0">
                                <button className="nav-link active" onClick={handleLogout} title='logout' to="/">
                                    <i className="fa-solid fa-arrow-right-from-bracket text-start"></i>
                                    <p>Logout</p>
                                </button>
                            </li>
                        </ul>

                        {/* <hr className="navbar-divider my-5 opacity-20" /> */}

                        {/* <div className="mt-auto"></div>

                            <ul className="navbar-nav d-none d-lg-block">
                                <li className="nav-item">
                                    <Link className="nav-link" to="/profile">
                                        <i className="bi bi-person-square"></i> Change Password
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <button className="my-btng nav-link" onClick={handleLogout}>
                                        <i className="bi bi-box-arrow-left"></i> Logout
                                    </button>
                                </li>
                            </ul> */}
                    </div>
                </div>
            </nav>
        </div>
    )
}

export default Navbar
