import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Slider from './Slider';
import { ToastContainer, toast } from 'react-toastify';
import ProgressBar from './ProgressBar';

const EducationalDetails = () => {
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        school_college_name: '',
        school_college_state: '',
        school_college_city: '',
        tenth_institute_name: '',
        tenth_board: '',
        tenth_year_of_passing: '',
        twelfth_result_status: '',
        tenth_marking_scheme: '',
        tenth_obtained_marks: '',
        tenth_max_marks: '',
        twelfth_institute_name: '',
        twelfth_board: '',
        twelfth_year_of_passing: '',
        diploma_result_status: '',
        twelfth_marking_scheme: '',
        twelfth_obtained_marks: '',
        twelfth_max_marks: '',
        diploma_institute_name: '',
        diploma_board: '',
        diploma_year_of_passing: '',
        diploma_marking_scheme: '',
        diploma_obtained_marks: '',
        diploma_max_marks: '',
        qualifying_exam: '',
        twelfth_roll_no: '',
    });

    const [courses, setCourses] = useState([]);

    useEffect(() => {
        // Fetch courses from the API
        fetch('/api/get_course')
            .then(response => response.json())
            .then(data => {
                setCourses(data); // Assuming the API returns an array of course objects
            })
            .catch(error => {
                console.error('Error fetching courses:', error);
            });
    }, []);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        console.log(formData);

        const totalFields = Object.keys(formData).length;
        const filledFields = Object.values(formData).filter(value => value !== null && value !== '').length;
        const progress = (filledFields / totalFields) * 100;
        const progress2 = Math.round(progress);
        console.log("field counts", totalFields, filledFields, progress);

        try {
            const response = await fetch(`/api/student_application?validate=validate_form&step=2&completion_status=${progress2}`, {
                method: "POST",
                body: JSON.stringify(formData)
            });
            const data = await response.json();
            console.log(data);
            if (data.suc) {
                toast.success(data.msg);
                navigate('/form/documents')
            }
            else {
                toast.error(data.msg);
            }
        }
        catch (error) {
            toast.error("something went wrong");
            console.error(error);
        }
    };

    const handleSaveData = async () => {
        console.log(formData);

        const totalFields = Object.keys(formData).length;
        const filledFields = Object.values(formData).filter(value => value !== null && value !== '').length;
        const progress = (filledFields / totalFields) * 100;
        const progress2 = Math.round(progress);
        console.log("field counts", totalFields, filledFields, progress);

        try {
            const response = await fetch(`/api/student_application?completion_status=${progress2}`, {
                method: "POST",
                body: JSON.stringify(formData)
            })
            const data = await response.json();
            console.log(data);
            if (data.suc) {
                toast.success(data.msg);
            }
            else {
                toast.error(data.msg);
            }
        }
        catch (error) {
            toast.error("something went wrong");
            console.error(error);
        }
    };

    const handleLogout = () => {
        localStorage.removeItem('token');
        window.location.href = "/logout";
    };

    useEffect(() => {
        getStudentData();
    }, [])

    const getStudentData = async () => {
        const response = await fetch("/api/fetch_student_application");
        const data1 = await response.json();
        console.log("student data", data1);
        if (data1) {
            setFormData(data1.data[0]);
        }
    }

    const handleBack = () => {
        navigate('/form')
    }

    return (
        <div>
            <ToastContainer />
            <header>
                <div className="container5 fixed-header py-2 px-3" style={{ boxShadow: "rgb(194 197 201) 0px 4px 20px" }}>
                    <nav>
                        <div className="navbar-header d-flex justify-content-between align-items-center">
                            <Link to='/form'>
                                <img src="https://cdn.npfs.co/uploads/college/image/5f2a94bfe915e036895412_Webp.net_resizeimage.png" style={{ width: "10rem" }} alt="Logo" />
                            </Link>
                            <ul className='m-0 p-0' style={{ listStyle: "none" }}>
                                <li>
                                    <button type='button' className="btn btn-primary" onClick={handleLogout} title='logout'>
                                        <i className="fa-solid fa-arrow-right-from-bracket text-start"></i> Logout
                                    </button>
                                </li>
                            </ul>
                        </div>
                    </nav>
                </div>
            </header>

            <div className="container mb-4" style={{ marginTop: "6.5rem" }}>
                <div>
                    <Slider activeIndex={1} />
                </div>

                <div>
                    <ProgressBar formData={formData} />
                </div>
            </div>

            <div className="container my-5 mt-0">
                <div className="panel">
                    <div className="panel-body">
                        <div className="row">
                            <form onSubmit={handleSubmit}>
                                <div className="row">
                                    <div className="col-md-12 heading">
                                        <h5>Academic Details</h5>
                                    </div>

                                    <div className="col-12 course">
                                        <label className="form-label" htmlFor="tenth_institute_name">10th Details</label>
                                        <table className="table table-bordered edu-table">
                                            <thead>
                                                <tr>
                                                    <th></th>
                                                    <th>Institute Name <span className='star'>*</span></th>
                                                    <th>Board <span className='star'>*</span></th>
                                                    <th>Year of Passing <span className='star'>*</span></th>
                                                    <th>Marking Scheme <span className='star'>*</span></th>
                                                    <th>Obtained Marks/ Percentage/ CGPA/Grade <span className='star'>*</span></th>
                                                    <th>Maximum Marks/ Percentage/ CGPA/Grade <span className='star'>*</span></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        <p>10th</p>
                                                    </td>
                                                    <td>
                                                        <input type="text" id='tenth_institute_name' name='tenth_institute_name' className='form-input' value={formData.tenth_institute_name} required onChange={handleChange} />
                                                    </td>
                                                    <td>
                                                        <select className="my-form-select3" id='tenth_board' name='tenth_board' value={formData.tenth_board} required onChange={handleChange} aria-label="Default select example">
                                                            <option value="">Select</option>
                                                            <option value="CBSC">CBSC</option>
                                                            <option value="Haryana Board">Haryana Board</option>
                                                        </select>
                                                    </td>
                                                    <td>
                                                        <select
                                                            id="tenth_year_of_passing"
                                                            name="tenth_year_of_passing"
                                                            className="form-input"
                                                            value={formData.tenth_year_of_passing}
                                                            required onChange={handleChange}
                                                        >
                                                            <option value="">Select</option>
                                                            {Array.from(new Array(50), (v, i) => (
                                                                <option key={i} value={new Date().getFullYear() - i}>
                                                                    {new Date().getFullYear() - i}
                                                                </option>
                                                            ))}
                                                        </select>
                                                    </td>
                                                    <td>
                                                        <select className="my-form-select3" id='tenth_marking_scheme' name='tenth_marking_scheme' value={formData.tenth_marking_scheme} required onChange={handleChange} aria-label="Default select example">
                                                            <option value="">Select</option>
                                                            <option value="Percentage">Percentage</option>
                                                            <option value="CGPA">CGPA</option>
                                                            <option value="Marks">Marks</option>
                                                            <option value="Grade">Grade</option>
                                                        </select>
                                                    </td>
                                                    <td>
                                                        <input type="text" id='tenth_obtained_marks' name='tenth_obtained_marks' className='form-input' value={formData.tenth_obtained_marks} required onChange={handleChange} />
                                                    </td>
                                                    <td>
                                                        <input type="text" id='tenth_max_marks' name='tenth_max_marks' className='form-input' value={formData.tenth_max_marks} required onChange={handleChange} />
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>

                                    <div className="col-md-6 mb-md-4 mb-2">
                                        <div className="form-group">
                                            <label className="form-label" htmlFor="qualifying_exam">Name of the Qualifying Exam <span className='star'>*</span></label>
                                            <select className="my-form-select3" id='qualifying_exam' name='qualifying_exam' value={formData.qualifying_exam} required onChange={handleChange} aria-label="Default select example">
                                                <option value="">Select</option>
                                                <option value="XIIth">XIIth</option>
                                                <option value="Diploma">Diploma</option>
                                                <option value="Both">Both</option>
                                            </select>
                                        </div>
                                    </div>

                                    {(formData.qualifying_exam === "XIIth" || formData.qualifying_exam === "Both") && <div className="col-12 course">
                                        <label className="form-label" htmlFor="twelfth_institute_name">12th Details</label>
                                        <table className="table table-bordered edu-table">
                                            <thead>
                                                <tr>
                                                    <th></th>
                                                    <th>Institute Name <span className='star'>*</span></th>
                                                    <th>Board <span className='star'>*</span></th>
                                                    <th>Year of Passing <span className='star'>*</span></th>
                                                    <th>Result Status <span className='star'>*</span></th>
                                                    <th>Marking Scheme {formData.twelfth_result_status === "Declared" && <span className='star'>*</span>}</th>
                                                    <th>Obtained Marks/ Percentage/ CGPA/Grade {formData.twelfth_result_status === "Declared" && <span className='star'>*</span>}</th>
                                                    <th>Maximum Marks/ Percentage/ CGPA/Grade {formData.twelfth_result_status === "Declared" && <span className='star'>*</span>}</th>
                                                    <th>Roll No.</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        <p>12th</p>
                                                    </td>
                                                    <td>
                                                        <input type="text" id='twelfth_institute_name' name='twelfth_institute_name' className='form-input' value={formData.twelfth_institute_name} required onChange={handleChange} />
                                                    </td>
                                                    <td>
                                                        <select className="my-form-select3" id='twelfth_board' name='twelfth_board' value={formData.twelfth_board} required onChange={handleChange} aria-label="Default select example">
                                                            <option value="">Select</option>
                                                            <option value="CBSC">CBSC</option>
                                                            <option value="Haryana Board">Haryana Board</option>
                                                        </select>
                                                    </td>
                                                    <td>
                                                        <select
                                                            id="twelfth_year_of_passing"
                                                            name="twelfth_year_of_passing"
                                                            className="form-input"
                                                            value={formData.twelfth_year_of_passing}
                                                            required onChange={handleChange}
                                                        >
                                                            <option value="">Select Year</option>
                                                            {Array.from(new Array(50), (v, i) => (
                                                                <option key={i} value={new Date().getFullYear() - i}>
                                                                    {new Date().getFullYear() - i}
                                                                </option>
                                                            ))}
                                                        </select>
                                                    </td>
                                                    <td>
                                                        <select className="my-form-select3" id='twelfth_result_status' name='twelfth_result_status' value={formData.twelfth_result_status} required onChange={handleChange} aria-label="Default select example">
                                                            <option value="">Select</option>
                                                            <option value="Awaited">Awaited</option>
                                                            <option value="Declared">Declared</option>
                                                        </select>
                                                    </td>
                                                    <td>
                                                        {formData.twelfth_result_status === "Declared" && <select className="my-form-select3" id='twelfth_marking_scheme' name='twelfth_marking_scheme' value={formData.twelfth_marking_scheme} required onChange={handleChange} aria-label="Default select example">
                                                            <option value="">Select</option>
                                                            <option value="Percentage">Percentage</option>
                                                            <option value="CGPA">CGPA</option>
                                                            <option value="Marks">Marks</option>
                                                            <option value="Grade">Grade</option>
                                                        </select>}
                                                    </td>
                                                    <td>
                                                        {formData.twelfth_result_status === "Declared" && <input type="text" id='twelfth_obtained_marks' name='twelfth_obtained_marks' className='form-input' value={formData.twelfth_obtained_marks} required onChange={handleChange} />}
                                                    </td>
                                                    <td>
                                                        {formData.twelfth_result_status === "Declared" && <input type="text" id='twelfth_max_marks' name='twelfth_max_marks' className='form-input' value={formData.twelfth_max_marks} required onChange={handleChange} />}
                                                    </td>
                                                    <td>
                                                        <input type="text" id='twelfth_roll_no' name='twelfth_roll_no' className='form-input' value={formData.twelfth_roll_no} onChange={handleChange} />
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>}

                                    {(formData.qualifying_exam === "Diploma" || formData.qualifying_exam === "Both") && <div className="col-12 course">
                                        <label className="form-label" htmlFor="diploma_institute_name">Diploma Details</label>
                                        <table className="table table-bordered edu-table">
                                            <thead>
                                                <tr>
                                                    <th></th>
                                                    <th>Institute Name <span className='star'>*</span></th>
                                                    <th>Board <span className='star'>*</span></th>
                                                    <th>Year of Passing <span className='star'>*</span></th>
                                                    <th>Result Status <span className='star'>*</span></th>
                                                    <th>Marking Scheme {formData.diploma_result_status === "Declared" && <span className='star'>*</span>}</th>
                                                    <th>Obtained Marks/ Percentage/ CGPA/Grade {formData.diploma_result_status === "Declared" && <span className='star'>*</span>}</th>
                                                    <th>Maximum Marks/ Percentage/ CGPA/Grade {formData.diploma_result_status === "Declared" && <span className='star'>*</span>}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        <p>Diploma</p>
                                                    </td>
                                                    <td>
                                                        <input type="text" id='diploma_institute_name' name='diploma_institute_name' className='form-input' value={formData.diploma_institute_name} required onChange={handleChange} />
                                                    </td>
                                                    <td>
                                                        <select className="my-form-select3" id='diploma_board' name='diploma_board' value={formData.diploma_board} required onChange={handleChange} aria-label="Default select example">
                                                            <option value="">Select</option>
                                                            <option value="CBSC">CBSC</option>
                                                            <option value="Haryana Board">Haryana Board</option>
                                                        </select>
                                                    </td>
                                                    <td>
                                                        <select
                                                            id="diploma_year_of_passing"
                                                            name="diploma_year_of_passing"
                                                            className="form-input"
                                                            value={formData.diploma_year_of_passing}
                                                            required onChange={handleChange}
                                                        >
                                                            <option value="">Select Year</option>
                                                            {Array.from(new Array(50), (v, i) => (
                                                                <option key={i} value={new Date().getFullYear() - i}>
                                                                    {new Date().getFullYear() - i}
                                                                </option>
                                                            ))}
                                                        </select>
                                                    </td>
                                                    <td>
                                                        <select className="my-form-select3" id='diploma_result_status' name='diploma_result_status' value={formData.diploma_result_status} required onChange={handleChange} aria-label="Default select example">
                                                            <option value="">Select</option>
                                                            <option value="Awaited">Awaited</option>
                                                            <option value="Declared">Declared</option>
                                                        </select>
                                                    </td>
                                                    <td>
                                                        {formData.diploma_result_status === "Declared" && <select className="my-form-select3" id='diploma_marking_scheme' name='diploma_marking_scheme' value={formData.diploma_marking_scheme} required onChange={handleChange} aria-label="Default select example">
                                                            <option value="">Select</option>
                                                            <option value="Percentage">Percentage</option>
                                                            <option value="CGPA">CGPA</option>
                                                            <option value="Marks">Marks</option>
                                                            <option value="Grade">Grade</option>
                                                        </select>}
                                                    </td>
                                                    <td>
                                                        {formData.diploma_result_status === "Declared" && <input type="text" id='diploma_obtained_marks' name='diploma_obtained_marks' className='form-input' value={formData.diploma_obtained_marks} required onChange={handleChange} />}
                                                    </td>
                                                    <td>
                                                        {formData.diploma_result_status === "Declared" && <input type="text" id='diploma_max_marks' name='diploma_max_marks' className='form-input' value={formData.diploma_max_marks} required onChange={handleChange} />}
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>}
                                </div>

                                <div className="line">
                                    <hr style={{ border: 'none', borderTop: '1px dashed black' }} />
                                </div>

                                <div className="row align-items-center">
                                    <div className="col-md-12 heading">
                                        <h5>Name and Address of Last Attended School/College</h5>
                                    </div>

                                    <div className="col-md-4 mb-md-4 mb-2">
                                        <div className="form-group">
                                            <label className="form-label" htmlFor="school_college_name">School / College Name <span className='star'>*</span></label>
                                            <input type="text" id='school_college_name' name='school_college_name' className='form-input' placeholder="Enter Your School / College Name" value={formData.school_college_name} required onChange={handleChange} />
                                        </div>
                                    </div>

                                    <div className="col-md-4 mb-md-4 mb-2 course dropdown">
                                        <div className="form-group">
                                            <label className="form-label" htmlFor="school_college_state">School / College State <span className='star'>*</span></label>
                                            <select className="my-form-select3" id='school_college_state' name='school_college_state' value={formData.school_college_state} required onChange={handleChange} aria-label="Default select example">
                                                <option value="">Select</option>
                                                <option value="Haryana">Haryana</option>
                                                <option value="Punjab">Punjab</option>
                                                <option value="Delhi">Delhi</option>
                                            </select>
                                        </div>
                                    </div>

                                    <div className="col-md-4 mb-md-4 mb-2 course dropdown">
                                        <div className="form-group">
                                            <label className="form-label" htmlFor="school_college_city">School / College City <span className='star'>*</span></label>
                                            <select className="my-form-select3" id='school_college_city' name='school_college_city' value={formData.school_college_city} required onChange={handleChange} aria-label="Default select example">
                                                <option value="">Select</option>
                                                <option value="Chandigarh">Chandigarh</option>
                                                <option value="Delhi">Delhi</option>
                                                <option value="Mumbai">Mumbai</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>

                                <div className='d-none d-sm-block'>
                                    <div className="row align-items-center">
                                        <div className="col-6">
                                            <button type='button' onClick={handleBack} className='btn btn-secondary me-2' style={{ borderRadius: "unset" }}>BACK</button>
                                            <button type='button' onClick={handleSaveData} className='btn btn-secondary' style={{ borderRadius: "unset" }}>SAVE & EXIT</button>
                                        </div>
                                        <div className="col-6 text-end">
                                            <button type='submit' className='btn btn-primary' style={{ borderRadius: "unset" }}>NEXT</button>
                                        </div>
                                    </div>
                                </div>

                                <div className='d-block d-sm-none'>
                                    <div className="row align-items-center justify-content-between">
                                        <div className="col-3">
                                            <button type='button' onClick={handleBack} className='btn btn-success' style={{ borderRadius: "50%" }}><i class="fa-solid fa-chevron-left"></i></button>
                                        </div>
                                        <div className="col-6 text-center">Step 2 of 5</div>
                                        <div className="col-3 text-end">
                                            <button type='submit' className='btn btn-success' style={{ borderRadius: "50%" }}><i class="fa-solid fa-chevron-right"></i></button>
                                        </div>
                                        <div className="col-12 mt-3">
                                            <button type='button' onClick={handleSaveData} className='btn btn-outline-secondary' style={{ borderRadius: "unset", width: "100%" }}>SAVE & EXIT</button>
                                        </div>
                                    </div>
                                </div>

                            </form>
                        </div>
                    </div>
                </div>
            </div>

            <footer>
                <div className="footer text-end p-3" style={{ backgroundColor: "#E5E5E5" }}>
                    <img src="https://cresttechnosoft.com/wp-content/uploads/2024/08/logo-3.svg" style={{ width: "8rem" }} alt="Footer Logo" />
                </div>
            </footer>
        </div>
    );
};

export default EducationalDetails;
