import { useEffect, useState } from 'react';
import React from 'react';
import Navbar from './Navbar'; // Assuming you still need to include the Navbar
import { toast, ToastContainer } from 'react-toastify';
import markIcon from '../images/mark.svg';
import bellIcon from '../images/bell.svg';
import { Multiselect } from 'multiselect-react-dropdown';

const AddUser = () => {
    const [formData, setFormData] = useState({
        username: '',
        email: '',
        role: 'select',
        course: '',
        password: '',
    });

    const [tableData, setTableData] = useState([]);
    const [selectedView, setSelectedView] = useState('admin');

    useEffect(() => {
        const link = document.createElement('link');
        const link2 = document.createElement('link');
        link.href = 'https://unpkg.com/@webpixels/css@1.1.5/dist/index.css';
        link.rel = 'stylesheet';

        link2.href = 'https://cdnjs.cloudflare.com/ajax/libs/bootstrap-icons/1.4.0/font/bootstrap-icons.min.css';
        link2.rel = 'stylesheet';

        document.head.appendChild(link);
        document.head.appendChild(link2);

        return () => {
            document.head.removeChild(link);
            document.head.removeChild(link2);
        };
    }, []);

    useEffect(() => {
        fetchData(selectedView);
    }, [selectedView]);

    const [courses, setCourses] = useState([]);

    useEffect(() => {
        // Fetch courses from the API
        fetch(`/api/get_course`)
            .then(response => response.json())
            .then(data => {
                setCourses(data); // Assuming the API returns an array of course objects
            })
            .catch(error => {
                console.error('Error fetching courses:', error);
            });
    }, []);

    const fetchData = async (view) => {
        try {
            const response = await fetch(`/api/view_users_data/${view}`);
            const data = await response.json();
            if (!data.suc) {
                setTableData(data);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
            toast.error("something went wrong");
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const [selectedCourses, setSelectedCourses] = useState([]);

    // const handleChange2 = (e) => {
    //     const { value } = e.target;

    //     // Find the course name based on selected course id
    //     const selectedCourseName = courses.find(course => course.id === parseInt(value))?.name;

    //     // Add the course to selectedCourses if it's not already there
    //     // if (selectedCourseName && !selectedCourses.includes(selectedCourseName)) {
    //     setSelectedCourses([...selectedCourses, selectedCourseName]);
    //     // }

    //     // Update the formData for course
    //     setFormData({
    //         ...formData,
    //         course: value,
    //     });
    // };

    const handleSelect = (selectedList, selectedItem) => {
        setSelectedCourses(selectedList);
        console.log(selectedCourses) // Update the selected courses with the list
    };

    const handleRemove = (selectedList, removedItem) => {
        setSelectedCourses(selectedList); // Update the list when a course is removed
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        console.log('Form data submitted:', formData);

        const formData2 = new FormData();
        formData2.append('username', formData.username);
        formData2.append('email', formData.email);
        formData2.append('role', formData.role);
        formData2.append('password', formData.password);
        if(formData.role === "2"){
            for(let i=0; i<selectedCourses.length; i++){
                formData2.append(`course[${i}]`, selectedCourses[i].id);
            }
        }

        console.log([...formData2]);

        const response = await fetch(`/auth/register_user`, {
            method: "POST",
            body: formData2
        });
        const data = await response.json();
        console.log(data);

        if (data.suc) {
            toast.success(data.msg);
            setFormData({
                username: '',
                email: '',
                role: 'select',
                password: '',
            });
        } else {
            toast.warn(data.msg);
        }
    };

    return (
        <div>
            <ToastContainer />
            <div className="d-flex flex-column flex-lg-row h-lg-full bg-surface-secondary">
                <Navbar />
                <div className="d-flex flex-grow-1 overflow-y-lg-auto">
                    <main className="py-0 pt-0 bg-surface-secondary w-100">
                        <div className="card-header mb-1" style={{ position: "sticky", top: "0", zIndex: "1" }}>
                            <div className="d-flex flex-wrap align-items-center justify-content-end">

                                <div className="icons">
                                    <div className="d-flex">
                                        <div className="icon-1 px-2">
                                            <img src={markIcon} alt="" />
                                        </div>
                                        <div className="icon-1 px-2">
                                            <img src={bellIcon} alt="" />
                                        </div>
                                        <div className="icon-1 px-2">
                                            <div className="profile" style={{ borderRadius: "50%", padding: "2px 5px", backgroundColor: "#F0EEEE" }}>
                                                CG
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className="card-header mb-5">
                            <div className="row align-items-center justify-content-between text-center">
                                <div className="col-md-9 mb-0 mb-md-0 text-start">
                                    <div className="row">
                                        <div className="col-12">
                                            <h4>Add a New User</h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-3 mb-3 mb-md-0">
                                    <div className="dropdown">
                                        <button className="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-expanded="false">
                                            View {selectedView.charAt(0).toUpperCase() + selectedView.slice(1)}
                                        </button>
                                        <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                            <li><button className="dropdown-item" onClick={() => setSelectedView('admin')}>View Admins</button></li>
                                            <li><button className="dropdown-item" onClick={() => setSelectedView('employes')}>View Employees</button></li>
                                            <li><button className="dropdown-item" onClick={() => setSelectedView('students')}>View Students</button></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="form-container">
                                        <form onSubmit={handleSubmit}>
                                            <div className="form-group2">
                                                <label htmlFor="username">Username:</label>
                                                <input
                                                    className="user-select"
                                                    type="text"
                                                    id="username"
                                                    name="username"
                                                    value={formData.username}
                                                    onChange={handleChange}
                                                    required
                                                />
                                            </div>
                                            <div className="form-group2">
                                                <label htmlFor="email">Email:</label>
                                                <input
                                                    className="user-select"
                                                    type="email"
                                                    id="email"
                                                    name="email"
                                                    value={formData.email}
                                                    onChange={handleChange}
                                                    required
                                                />
                                            </div>
                                            <div className="form-group2">
                                                <label htmlFor="role">Role:</label>
                                                <select
                                                    className='user-select'
                                                    id="role"
                                                    name="role"
                                                    value={formData.role}
                                                    onChange={handleChange}
                                                    required
                                                >
                                                    <option value="">Select role</option>
                                                    <option value="1">Admin</option>
                                                    <option value="2">Employee</option>
                                                    <option value="3">Student</option>
                                                </select>
                                            </div>

                                            {formData.role === "2" && (
                                                <div className="form-group2">
                                                    <label htmlFor="course">Course</label>
                                                    <Multiselect
                                                        options={courses} // Options to display in the dropdown
                                                        selectedValues={selectedCourses} // Preselected value to persist in dropdown
                                                        onSelect={handleSelect} // Triggered when an item is selected
                                                        onRemove={handleRemove} // Triggered when an item is removed
                                                        displayValue="name" // Property name to display in the dropdown options
                                                        placeholder="Select courses"
                                                    />
                                                </div>
                                            )}

                                            {/* {formData.role === "2" && (
                                                <div className="form-group2">
                                                    <label htmlFor="course">Course</label>
                                                    <select
                                                        className="user-select"
                                                        id='course'
                                                        name='course'
                                                        value={formData.course}
                                                        onChange={handleChange2}
                                                        aria-label="Select a course"
                                                    >
                                                        <option value="">Select</option>
                                                        {courses.map((course, index) => (
                                                            <option key={index} value={course.id}>{course.name}</option>
                                                        ))}
                                                    </select>
                                                </div>
                                            )} */}

                                            <div className="form-group2">
                                                <label htmlFor="password">Password:</label>
                                                <input
                                                    className="user-select"
                                                    type="password"
                                                    id="password"
                                                    name="password"
                                                    value={formData.password}
                                                    onChange={handleChange}
                                                    required
                                                />
                                            </div>
                                            <button className='user-btn' type="submit">Submit</button>
                                        </form>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="table-container" style={{ overflowX: 'auto', overflowY: 'auto', maxHeight: 'calc(100vh - 150px)' }}>
                                        <table className="table table-striped">
                                            <thead>
                                                <tr>
                                                    {tableData.length > 0 && Object.keys(tableData[0]).map(key => (
                                                        (key !== "password" && key !== "role_id" && key !== "auth_token" && key !== "settings" && key !== "modified_at" && <th key={key}>{key}</th>)

                                                    ))}
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {tableData.map((item, rowIndex) => (
                                                    <tr key={rowIndex}>
                                                        {Object.keys(tableData[0]).map((key, colIndex) => (
                                                            key !== "password" && key !== "role_id" && key !== "auth_token" && key !== "settings" && key !== "modified_at" ? (
                                                                <td key={colIndex}>{item[key]}</td>
                                                            ) : null
                                                        ))}
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </main>
                </div>
            </div>
        </div>
    );
};

export default AddUser;
