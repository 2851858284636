import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Slider from './Slider';
import { ToastContainer, toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import ProgressBar from './ProgressBar';

const Form = () => {

  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    course: '',
    name: '',
    email: '',
    phone: '',
    alternate_phone: '',
    dob: '',
    gender: '',
    category: '',
    info: '',
    religion: '',
    father_name: '',
    father_phone_number: '',
    father_occupation: '',
    mother_name: '',
    mother_phone_number: '',
    mother_occupation: '',
    country: '',
    address: '',
    pincode: '',
    fees: '',
    hostel_facility: '',
    transport_facility: '',
  });
  const [courses, setCourses] = useState([]);

  useEffect(() => {
    // Fetch courses from the API
    fetch(`/api/get_course`)
      .then(response => response.json())
      .then(data => {
        setCourses(data); // Assuming the API returns an array of course objects
        console.log("courses......", data)
      })
      .catch(error => {
        console.error('Error fetching courses:', error);
      });
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
    
  };

  const handleMouseOut = ()=>{
    handleSaveData(true);
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(formData);

    try {
      const totalFields = Object.keys(formData).length;
      const filledFields = Object.values(formData).filter(value => value !== null && value !== '').length;
      const progress = (filledFields / totalFields) * 100;
      const progress2 = Math.round(progress);
      console.log("field counts", totalFields, filledFields, progress);

      const response = await fetch(`/api/student_application?validate=validate_form&step=1&completion_status=${progress2}`, {
        method: "POST",
        body: JSON.stringify(formData)
      })
      const data = await response.json();
      console.log(data);
      if (data.suc) {
        toast.success(data.msg);
        navigate('/form/educational_details');
      }
      else {
        toast.error(data.msg);
      }
    }
    catch (error) {
      toast.error("something went wrong");
      console.error(error);
    }
  };

  const handleSaveData = async (mouseOut) => {

    const totalFields = Object.keys(formData).length;
    const filledFields = Object.values(formData).filter(value => value !== null && value !== '').length;
    const progress = (filledFields / totalFields) * 100;
    const progress2 = Math.round(progress);
    console.log("field counts", totalFields, filledFields, progress);

    try {
      const response = await fetch(`/api/student_application?completion_status=${progress2}`, {
        method: "POST",
        body: JSON.stringify(formData)
      })
      const data = await response.json();
      console.log(data);
      if(!mouseOut){
        if (data.suc) {
          toast.success(data.msg);
        }
        else {
          toast.error(data.msg);
        }
      }
    }
    catch (error) {
      toast.error("something went wrong");
      console.error(error);
    }
  };

  const handleLogout = () => {
    localStorage.removeItem('token');
    window.location.href = "/logout";
  }

  useEffect(() => {
    getStudentData();
  }, [])

  const getStudentData = async () => {
    const response = await fetch("/api/fetch_student_application");
    const data1 = await response.json();
    console.log("student data", data1);
    if (data1) {
      setFormData(data1.data[0]);

    }

  }

  return (
    <div>
      <ToastContainer />
      <header>
        <div className="container5 fixed-header py-2 px-3" style={{ boxShadow: "rgb(194 197 201) 0px 4px 20px" }}>
          <nav>
            <div className="navbar-header d-flex justify-content-between align-items-center">
              <Link to='/form'>
                <img src="https://cdn.npfs.co/uploads/college/image/5f2a94bfe915e036895412_Webp.net_resizeimage.png" style={{ width: "10rem" }} alt="Logo" />
              </Link>

              <ul className='m-0 p-0' style={{ listStyle: "none" }}>
                <li>
                  <button type='btn' className="btn btn-primary" onClick={handleLogout} title='logout' to="/">
                    <i className="fa-solid fa-arrow-right-from-bracket text-start"></i> Logout
                  </button>
                </li>
              </ul>

            </div>
          </nav>
        </div>
      </header>

      <div className="container mb-4" style={{ marginTop: "6.5rem" }}>
        <div>
          <Slider activeIndex={0} />
        </div>

        <div>
          <ProgressBar formData={formData} />
        </div>

        <div className="instructions panel">
          <div className="panel-body">
            <h5><i className="fa-solid fa-circle-info"></i> Instructions</h5>
            <div className="inner-info">
              <ul>
                <li>All fields marked with* are mandatory to be filled.</li>
                <li>An application number will be displayed, once you successfully submit the form by making payment.</li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="container my-5 mt-0">
        <div className="panel">
          <div className="panel-body">
            <div className="row">
              <form onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-md-12 heading">
                    <h5>Course Details</h5>
                  </div>

                  <div className="col-md-4 course">
                    <div className="form-group">
                      <label className="form-label" htmlFor="course">Course <span className='star'>*</span></label>
                      <select className="my-form-select3" required disabled={formData.course} id='course' name='course' value={formData.course || ""} onChange={handleChange} aria-label="Default select example">
                        <option value="">Select</option>
                        {courses.map((course, index) => (
                          <option key={index} value={course.id}>{course.name}</option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>

                <div className="line">
                  <hr style={{ border: 'none', borderTop: '1px dashed black' }} />
                </div>

                <div className="row align-items-center">
                  <div className="col-md-12 heading">
                    <h5>Personal Details</h5>
                  </div>

                  <div className="col-md-4 mb-md-4 mb-2">
                    <div className="form-group">
                      <label className="form-label" htmlFor="name">Full Name <span className='star'>*</span></label>
                      <input type="text" required id='name' name='name' className='form-input' placeholder="Enter full name" value={formData.name || ""} onMouseOut={handleMouseOut} onChange={handleChange} />
                    </div>
                  </div>

                  <div className="col-md-4 mb-md-4 mb-2">
                    <div className="form-group">
                      <label className="form-label" htmlFor="email">Email Address <span className='star'>*</span> </label>
                      <input type="email" required id='email' disabled={formData.email} name='email' className='form-input' placeholder="Enter email address" value={formData.email} onMouseOut={handleMouseOut} onChange={handleChange} />
                    </div>
                  </div>

                  <div className="col-md-4 mb-md-4 mb-2">
                    <div className="form-group">
                      <label className="form-label" htmlFor="phone">Mobile Number <span className='star'>*</span></label>
                      <input type="number" required id='phone' disabled={formData.phone} name='phone' className='form-input' placeholder="Enter mobile number" value={formData.phone} onMouseOut={handleMouseOut} onChange={handleChange} />
                    </div>
                  </div>

                  <div className="col-md-4 mb-md-4 mb-2">
                    <div className="form-group">
                      <label className="form-label" htmlFor="alternate_phone">Alternate Mobile Number</label>
                      <input type="number" id='alternate_phone' name='alternate_phone' className='form-input' placeholder="Enter alternate mobile number" value={formData.alternate_phone} onMouseOut={handleMouseOut} onChange={handleChange} />
                    </div>
                  </div>

                  <div className="col-md-4 mb-md-4 mb-2">
                    <div className="form-group">
                      <label className="form-label" htmlFor="dob">Date of Birth <span className='star'>*</span></label>
                      <input type="date" required id='dob' name='dob' className='form-input' placeholder="Select date of birth" value={formData.dob} onMouseOut={handleMouseOut} onChange={handleChange} />
                    </div>
                  </div>

                  <div className="col-md-4 mb-md-4 mb-2 course dropdown">
                    <div className="form-group">
                      <label className="form-label" htmlFor="gender">Gender <span className='star'>*</span></label>
                      <select className="my-form-select3" required id='gender' name='gender' value={formData.gender} onMouseOut={handleMouseOut} onChange={handleChange} aria-label="Default select example">
                        <option value="">Select</option>
                        <option value="Male">Male</option>
                        <option value="Female">Female</option>
                        <option value="Third Gender">Third Gender</option>
                      </select>
                    </div>
                  </div>

                  <div className="col-md-4 mb-md-4 mb-2 course dropdown">
                    <div className="form-group">
                      <label className="form-label" htmlFor="category">Category <span className='star'>*</span></label>
                      <select className="my-form-select3" required id='category' name='category' value={formData.category} onMouseOut={handleMouseOut} onChange={handleChange} aria-label="Default select example">
                        <option value="">Select</option>
                        <option value="General">General</option>
                        <option value="SC">SC</option>
                        <option value="ST">ST</option>
                        <option value="OBC">OBC</option>
                      </select>
                    </div>
                  </div>

                  <div className="col-md-4 mb-md-4 mb-2 course dropdown">
                    <div className="form-group">
                      <label className="form-label" htmlFor="info">From where did you get to know about us? <span className='star'>*</span></label>
                      <select className="my-form-select3" required id='info' name='info' value={formData.info} onMouseOut={handleMouseOut} onChange={handleChange} aria-label="Default select example">
                        <option value="">Select</option>
                        <option value="google">Google</option>
                        <option value="newspaper">Newspaper</option>
                        <option value="magazine">Magazine</option>
                        <option value="brouchers">Brouchers</option>
                        <option value="digital_media">Digital Media</option>
                        <option value="radio">Radio</option>
                        <option value="hoarding">Hoarding</option>
                        <option value="education_fair">Education Fair</option>
                        <option value="coaching_centre">Coaching Centre</option>
                        <option value="friends_relatives">Friends / Relatives</option>
                        <option value="banners">Banners</option>
                        <option value="school_presentation">School Presentation</option>
                        <option value="other">Other</option>
                      </select>
                    </div>
                  </div>

                  <div className="col-md-4 mb-md-4 mb-2 course dropdown">
                    <div className="form-group">
                      <label className="form-label" htmlFor="religion">Religion <span className='star'>*</span></label>
                      <select className="my-form-select3" required id='religion' name='religion' value={formData.religion} onMouseOut={handleMouseOut} onChange={handleChange} aria-label="Default select example">
                        <option value="">Select</option>
                        <option value="Hindu">Hindu</option>
                        <option value="Muslim">Muslim</option>
                        <option value="Sikh">Sikh</option>
                        <option value="Other">Other</option>
                      </select>
                    </div>
                  </div>
                </div>

                <div className="line">
                  <hr style={{ border: 'none', borderTop: '1px dashed black' }} />
                </div>

                <div className="row align-items-center">
                  <div className="col-md-12 heading">
                    <h5>Parent's Details</h5>
                  </div>

                  <div className="col-md-4 mb-md-4 mb-2">
                    <div className="form-group">
                      <label className="form-label" htmlFor="father_name">Father's Name <span className='star'>*</span></label>
                      <input type="text" required id='father_name' name='father_name' className='form-input' placeholder="Enter father's name" value={formData.father_name} onMouseOut={handleMouseOut} onChange={handleChange} />
                    </div>
                  </div>

                  <div className="col-md-4 mb-md-4 mb-2">
                    <div className="form-group">
                      <label className="form-label" htmlFor="father_phone_number">Father's Mobile Number <span className='star'>*</span></label>
                      <input type="number" required id='father_phone_number' name='father_phone_number' className='form-input' placeholder="Enter father's mobile number" value={formData.father_phone_number} onMouseOut={handleMouseOut} onChange={handleChange} />
                    </div>
                  </div>

                  <div className="col-md-4 mb-md-4 mb-2 course dropdown">
                    <div className="form-group">
                      <label className="form-label" htmlFor="father_occupation">Father's Occupation</label>
                      <select className="my-form-select3" id='father_occupation' name='father_occupation' value={formData.father_occupation} onMouseOut={handleMouseOut} onChange={handleChange} aria-label="Default select example">
                        <option value="">Select</option>
                        <option value="agriculture">Agriculture</option>
                        <option value="business">Business</option>
                        <option value="defence">Defence</option>
                        <option value="government_sector">Government Sector</option>
                        <option value="homemaker">Homemaker</option>
                        <option value="private_sector">Private Sector</option>
                        <option value="retired">Retired</option>
                        <option value="other">Other</option>
                      </select>
                    </div>
                  </div>

                  <div className="col-md-4 mb-md-4 mb-2">
                    <div className="form-group">
                      <label className="form-label" htmlFor="mother_name">Mother's Name <span className='star'>*</span></label>
                      <input type="text" required id='mother_name' name='mother_name' className='form-input' placeholder="Enter mother's name" value={formData.mother_name} onMouseOut={handleMouseOut} onChange={handleChange} />
                    </div>
                  </div>

                  <div className="col-md-4 mb-md-4 mb-2">
                    <div className="form-group">
                      <label className="form-label" htmlFor="mother_phone_number">Mother's Mobile Number</label>
                      <input type="number" id='mother_phone_number' name='mother_phone_number' className='form-input' placeholder="Enter mother's mobile number" value={formData.mother_phone_number} onMouseOut={handleMouseOut} onChange={handleChange} />
                    </div>
                  </div>

                  <div className="col-md-4 mb-md-4 mb-2 course dropdown">
                    <div className="form-group">
                      <label className="form-label" htmlFor="mother_occupation">Mother's Occupation</label>
                      <select className="my-form-select3" id='mother_occupation' name='mother_occupation' value={formData.mother_occupation} onMouseOut={handleMouseOut} onChange={handleChange} aria-label="Default select example">
                        <option value="">Select</option>
                        <option value="agriculture">Agriculture</option>
                        <option value="business">Business</option>
                        <option value="defence">Defence</option>
                        <option value="government_sector">Government Sector</option>
                        <option value="homemaker">Homemaker</option>
                        <option value="private_sector">Private Sector</option>
                        <option value="retired">Retired</option>
                        <option value="other">Other</option>
                      </select>
                    </div>
                  </div>
                </div>

                <div className="line">
                  <hr style={{ border: 'none', borderTop: '1px dashed black' }} />
                </div>

                <div className="row align-items-center">
                  <div className="col-md-12 heading">
                    <h5>Address Details</h5>
                  </div>

                  <div className="col-md-4 mb-md-4 mb-2 course dropdown">
                    <div className="form-group">
                      <label className="form-label" htmlFor="country">Country <span className='star'>*</span></label>
                      <select className="my-form-select3" required id='country' name='country' value={formData.country} onMouseOut={handleMouseOut} onChange={handleChange} aria-label="Default select example">
                        <option value="">Select</option>
                        <option value="india">India</option>
                      </select>
                    </div>
                  </div>

                  <div className="col-md-4 mb-md-4 mb-2">
                    <div className="form-group">
                      <label className="form-label" htmlFor="address">Full Address <span className='star'>*</span></label>
                      <input type="text" required id='address' name='address' className='form-input' placeholder="Enter full address" value={formData.address} onMouseOut={handleMouseOut} onChange={handleChange} />
                    </div>
                  </div>

                  <div className="col-md-4 mb-md-4 mb-2">
                    <div className="form-group">
                      <label className="form-label" htmlFor="pincode">Pincode</label>
                      <input type="number" id='pincode' name='pincode' className='form-input' placeholder="Enter pincode" value={formData.pincode} onMouseOut={handleMouseOut} onChange={handleChange} />
                    </div>
                  </div>

                  <div className="col-md-6 mb-md-4 mb-2 course dropdown">
                    <div className="form-group">
                      <label className="form-label" htmlFor="fees">Fee To Be Paid <span className='star'>*</span></label>
                      <select className="my-form-select3" required id='fees' name='fees' value={formData.fees} onMouseOut={handleMouseOut} onChange={handleChange} aria-label="Default select example">
                        <option value="">Select</option>
                        <option value="registration_amount">Registration Amount</option>
                      </select>
                    </div>
                  </div>
                </div>

                <div className="row align-items-center">
                  <div className="col-md-12 heading">
                    <h5>Payment Details</h5>
                  </div>

                  <div className="col-md-6 mb-md-4 mb-2 course dropdown">
                    <div className="form-group">
                      <label className="form-label" htmlFor="hostel_facility">Do you need a hostel facility? <span className='star'>*</span></label>
                      <select className="my-form-select3" required id='hostel_facility' name='hostel_facility' value={formData.hostel_facility} onMouseOut={handleMouseOut} onChange={handleChange} aria-label="Default select example">
                        <option value="">Select</option>
                        <option value="yes">Yes</option>
                        <option value="no">No</option>
                      </select>
                    </div>
                  </div>

                  <div className="col-md-6 mb-4 course dropdown">
                    <div className="form-group">
                      <label className="form-label" htmlFor="transport_facility">Do you need transport facility? <span className='star'>*</span></label>
                      <select className="my-form-select3" required id='transport_facility' name='transport_facility' value={formData.transport_facility} onMouseOut={handleMouseOut} onChange={handleChange} aria-label="Default select example">
                        <option value="">Select</option>
                        <option value="yes">Yes</option>
                        <option value="no">No</option>
                      </select>
                    </div>
                  </div>
                </div>

                <div className='d-none d-sm-block'>
                  <div className="row align-items-center">
                    <div className="col-6">
                      <button type='button' onClick={() => handleSaveData(false)} className='btn btn-secondary' style={{ borderRadius: "unset" }}>SAVE & EXIT</button>
                    </div>
                    <div className="col-6 text-end">
                      <button type='submit' className='btn btn-primary' style={{ borderRadius: "unset" }}>NEXT</button>
                    </div>
                  </div>
                </div>

                <div className='d-block d-sm-none'>
                  <div className="row align-items-center">
                    <div className="col-3">
                      <button type='button' className='btn btn-success d-none' style={{ borderRadius: "50%" }}><i className="fa-solid fa-chevron-left"></i></button>
                    </div>
                    <div className="col-6 text-center">Step 1 of 5</div>
                    <div className="col-3 text-end">
                      <button type='submit' className='btn btn-success' style={{ borderRadius: "50%" }}><i className="fa-solid fa-chevron-right"></i></button>
                    </div>
                    <div className="col-12 mt-3">
                      <button type='button' onClick={() => handleSaveData(false)} className='btn btn-outline-secondary' style={{ borderRadius: "unset", width: "100%" }}>SAVE & EXIT</button>
                    </div>
                  </div>
                </div>

              </form>
            </div>
          </div>
        </div>
      </div>

      <footer>
        <div className="footer text-end p-3" style={{ backgroundColor: "#E5E5E5" }}>
          <img src="https://cresttechnosoft.com/wp-content/uploads/2024/08/logo-3.svg" style={{ width: "8rem" }} />
        </div>
      </footer>
    </div>
  )
}

export default Form;
