import React from 'react'
import { useState, useEffect } from 'react';

const LeadDetails = ({formData}) => {

    const [courses, setCourses] = useState([]);

    useEffect(() => {
        // Fetch courses from the API
        fetch(`/api/get_course`)
            .then(response => response.json())
            .then(data => {
                setCourses(data); // Assuming the API returns an array of course objects
                console.log("courses......", data)
            })
            .catch(error => {
                console.error('Error fetching courses:', error);
            });
    }, []);

    const selectedCourse = Object.values(courses).find(course => course.id === formData.course);
    

    return (
        <div>
            <div className="values px-4 py-3">
                <span>
                    <h4>Lead Details</h4>
                </span>
                <hr />
                <div className="d-flex flex-row mb-2">
                    <div className="col-3"><strong>Form Interested In</strong></div>
                    <div className="">: {formData.form_applied || "NA"}</div>
                </div>
                <div className="d-flex flex-row mb-2">
                    <div className="col-3"><strong>Email Address</strong></div>
                    <div className="">: {formData.email || "NA"}</div>
                </div>
                <div className="d-flex flex-row mb-2">
                    <div className="col-3"><strong>Mobile Number</strong></div>
                    <div className="">: {formData.phone || "NA"}</div>
                </div>
                <div className="d-flex flex-row mb-2">
                    <div className="col-3"><strong>Alternate Mobile Number</strong></div>
                    <div className="">: {formData.alternate_phone || "NA"}</div>
                </div>
                <div className="d-flex flex-row mb-2">
                    <div className="col-3"><strong>Name</strong></div>
                    <div className="">: {formData.name || "NA"}</div>
                </div>
                <div className="d-flex flex-row mb-2">
                    <div className="col-3"><strong>State</strong></div>
                    <div className="">: {formData.state || "NA"}</div>
                </div>
                <div className="d-flex flex-row mb-2">
                    <div className="col-3"><strong>City</strong></div>
                    <div className="">: {formData.city || "NA"}</div>
                </div>
                <div className="d-flex flex-row mb-2">
                    <div className="col-3"><strong>Course</strong></div>
                    <div className="">: {selectedCourse ? selectedCourse.name : "NA"}</div>
                </div>
                <div className="d-flex flex-row mb-2">
                    <div className="col-3"><strong>Lead Stage</strong></div>
                    <div className="">: {formData.lead_stage || "NA"}</div>
                </div>
                <div className="d-flex flex-row mb-2">
                    <div className="col-3"><strong>Lead Sub Stage</strong></div>
                    <div className="">: {formData.lead_sub_stage || "NA"} </div>
                </div>
                <div className="d-flex flex-row mb-2">
                    <div className="col-3"><strong>Forms Applied</strong></div>
                    <div className=""><span className='highlight'>: {formData.form_applied || "NA"}</span></div>
                </div>
            </div>
        </div>
    )
}

export default LeadDetails
