import React, { useEffect, useState, useCallback } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import RepeaterField from './RepeaterField';
import Modal from './Modal';
import ConfirmModal from './ConfirmModal';
import { useNavigate } from 'react-router-dom';
import Pagination from './Pagination';
import Navbar from './Navbar';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import searchIcon from '../images/search-normal.svg';
import deleteIcon from '../images/deleted.svg';
import columnIcon from '../images/Vector (1).svg';
import exportIcon from '../images/Group (1).svg';
import filterIcon from '../images/filter-icon1.svg';
import importIcon from '../images/Group.svg';
import markIcon from '../images/mark.svg';
import bellIcon from '../images/bell.svg';

const Dashboard = () => {

    useEffect(() => {
        // Initialize Bootstrap tooltips
        const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]');
        const tooltips = Array.from(tooltipTriggerList).map(tooltipTriggerEl => new window.bootstrap.Tooltip(tooltipTriggerEl));

    }, []);

    const navigate = useNavigate();

    useEffect(() => {
        if (!localStorage.getItem("token")) {

            navigate("/login");
        }
        else {
            verifyUser();
        }

        // eslint-disable-next-line
    }, [])

    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);

    const [currentPage2, setCurrentPage2] = useState(1);
    const [totalPages2, setTotalPages2] = useState(1);
    const [using, setUsing] = useState(false);

    const SECRET_CODE = 'crest@123';

    const [data, setData] = useState([]);

    useEffect(() => {
        const link = document.createElement('link');
        const link2 = document.createElement('link');
        link.href = 'https://unpkg.com/@webpixels/css@1.1.5/dist/index.css';
        link.rel = 'stylesheet';

        link2.href = 'https://cdnjs.cloudflare.com/ajax/libs/bootstrap-icons/1.4.0/font/bootstrap-icons.min.css';
        link2.rel = 'stylesheet';

        document.head.appendChild(link);
        document.head.appendChild(link2);

        return () => {
            document.head.removeChild(link);
            document.head.removeChild(link2);
        };
    }, []);

    const [loading, setLoading] = useState(true);

    const verifyUser = async () => {
        const token = localStorage.getItem("token");

        try {
            const response = await fetch(`/auth/get_user_data`, {
                headers: {
                    "Authorization": token
                }
            });
            const data = await response.json();
            console.log("auth_data", data);
            if (data.suc) {
                console.log("Authentication completed");
            }
            else {
                localStorage.removeItem('token');
                navigate("/login");
            }
        }
        catch (error) {
            console.error(error);
        }
    }

    const [totalResults, setTotalResults] = useState("");
    const [showingResults, setShowingResults] = useState("");

    const [limit, setLimit] = useState(20);
    const [countUsing, setCountUsing] = useState(false);
    const [filterUsing, setFilterUsing] = useState(false);

    const getLeads = async () => {
        try {
            console.log(countUsing);
            let pageLimit = limit;
            let page = (currentPage - 1) * pageLimit;

            if (countUsing) {
                page = (currentPage2 - 1) * pageLimit;
            }
            console.log(currentPage2)

            const response = await fetch(`/api/paginate_data/${page}?limit=${pageLimit}`);
            if (!response.ok) {
                throw new Error("Failed to fetch data");
            }
            const data1 = await response.json();
            console.log(data1);

            if (data1.suc) {
                setData(data1.results);
                setTotalPages(data1.total_pages);
                setTotalResults(data1.total_rows);
                setTotalPages2(data1.total_pages);
            }
            else {
                if (data1.msg) {
                    toast.error(data1.msg);
                }
            }
        }
        catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    const [checkboxState, setCheckboxState] = useState(null);
    const checkState = { name: true, number: true, email: true };

    const [selectedColumns, setSelectedColumns] = useState([]);
    const [showSelectedColumns, setShowSelectedColumns] = useState([]);

    const getLeadColumns = async () => {
        try {
            const response = await fetch("/api/get_lead_columns");
            const data = await response.json();
            console.log(data);
            if (!data.suc) {
                setCheckboxState(data);
                showData(data);
            }
            else {
                toast.error(data.msg);
            }
        }
        catch (error) {
            console.error(error);
        }
    }

    const showData = (data) => {
        const newSelectedColumns = Object.keys(data).filter(
            (column) => data[column]
        );
        setSelectedColumns(newSelectedColumns);
        setShowSelectedColumns(newSelectedColumns);
    };



    useEffect(() => {
        if (localStorage.getItem('token')) {
            getLeadColumns();
            if (!using) {
                getLeads();
            }
        }

        // eslint-disable-next-line
    }, [currentPage, limit, currentPage2]);


    const [allChecked, setAllChecked] = useState(false);
    const [checkedRows, setCheckedRows] = useState(
        data.reduce((acc, _, index) => {
            acc[index] = false;
            return acc;
        }, {})
    );

    const handleHeaderCheckboxChange = (event) => {
        const isChecked = event.target.checked;
        setAllChecked(isChecked);
        setCheckedRows(data.reduce((acc, _, index) => {
            acc[index] = isChecked;
            return acc;
        }, {}));
    };

    const handleRowCheckboxChange = (index) => (event) => {
        const isChecked = event.target.checked;
        setCheckedRows(prevCheckedRows => {
            const updatedCheckedRows = {
                ...prevCheckedRows,
                [index]: isChecked
            };

            const allCheckedNow = Object.values(updatedCheckedRows).every(value => value);
            setAllChecked(allCheckedNow);

            return updatedCheckedRows;
        });
    };

    useEffect(() => {
        setCheckedRows(data.reduce((acc, _, index) => {
            acc[index] = false;
            return acc;
        }, {}));
        setAllChecked(false);
    }, [data]);

    const handleCheckboxChange = useCallback((e, column) => {
        const isChecked = e.target.checked;
        setCheckboxState((prevState) => ({
            ...prevState,
            [column]: isChecked,
        }));

        if (isChecked) {
            setSelectedColumns((prevColumns) => [...prevColumns, column]);
        }
        else {
            setSelectedColumns((prevColumns) =>
                prevColumns.filter((item) => item !== column)
            );
        }

        // eslint-disable-next-line
    }, []);

    const handleDragEnd = useCallback((result) => {
        if (!result.destination) return;

        const reorderedColumns = Array.from(selectedColumns);
        const [movedColumn] = reorderedColumns.splice(result.source.index, 1);
        reorderedColumns.splice(result.destination.index, 0, movedColumn);

        setSelectedColumns(reorderedColumns);

        // eslint-disable-next-line
    }, [selectedColumns]);

    const removeColumn = useCallback((column) => {
        setSelectedColumns((prevColumns) =>
            prevColumns.filter((item) => item !== column)
        );
        setCheckboxState((prevState) => ({
            ...prevState,
            [column]: false,
        }));
        console.log(checkboxState);

        // eslint-disable-next-line
    }, []);



    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const [shouldRenderSidebar, setShouldRenderSidebar] = useState(false);

    const [check, setCheck] = useState(false);

    const toggleSidebar = () => {
        console.log(check)
        if (isSidebarOpen) {
            setIsSidebarOpen(false);
            setTimeout(() => setShouldRenderSidebar(false), 250);
        } else {
            setShouldRenderSidebar(true);
            setIsSidebarOpen(true);
        }
    };

    const getColumnValue = (row, column) => {
        if (column === "number") {
            column = "phone";
        }
        if (column === "course") {
            column = "course_name";
        }
        const key = column;
        return row[key] || 'N/A';
    };

    const [term, setTerm] = useState(false);
    const [show, setShow] = useState(false);

    const handleClick = () => {
        if (term) {
            setTerm(false);
            setTimeout(() => setShow(false), 300);
        }
        else {
            setTerm(true);
            setShow(true);
        }
    }

    const handleApply = async () => {
        console.log(selectedColumns);
        setShowSelectedColumns(selectedColumns);

        toggleSidebar();

        let order = {};

        let formData = new FormData();

        selectedColumns.forEach(element => {
            order[element] = true;
            formData.append(element, true);
        });

        console.log([...formData], "..", order);

        try{
            const response = await fetch("/api/save_column_orders", {
                method: "POST",
                body: formData
            });
            const data = await response.json();
            console.log("response data... ", data);
            if (data.suc) {
                toast.success(data.msg);
            }
            else {
                toast.error(data.msg);
            }
        }
        catch(error){
            toast.error("something went wrong");
            console.error(error);
        }

        
    }

    const handleExport = async () => {

        try {
            let apiUrl = 'api/export_leads';

            if (searchField !== "" || searchKeyword !== "") {
                apiUrl = `api/export_leads?field=${searchField}&search_keyword=${searchKeyword}`;
            }

            const checkedIds = Object.keys(checkedRows).filter(index => checkedRows[index]).map(index => data[index].id);

            let mainId = "";

            checkedIds.forEach((id) => {
                mainId = mainId + "," + id;
            });
            mainId = mainId.slice(1);
            console.log("ids are", mainId)

            const formData = new FormData()
            formData.append("id", mainId);

            const response = await fetch(apiUrl, {
                method: "POST",
                body: formData
            });

            if (!response.ok) {
                toast.error("something went wrong");
                throw new Error('Network response was not ok');
            }

            const data = await response.json();

            // Handle the response based on the `suc` field
            if (data.suc) {
                toast.success(data.msg);

                const csvData = data.result;

                const blob = new Blob([csvData], { type: 'text/csv' });

                // Process the exported data
                let filename = 'exported_data.csv';

                const contentDisposition = response.headers.get('Content-Disposition');
                if (contentDisposition) {
                    const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                    const matches = filenameRegex.exec(contentDisposition);
                    if (matches != null && matches[1]) {
                        filename = matches[1].replace(/['"]/g, '');
                    }
                }

                const url = window.URL.createObjectURL(new Blob([blob]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', filename);
                document.body.appendChild(link);
                link.click();

                link.parentNode.removeChild(link);
                window.URL.revokeObjectURL(url);
            } else {
                toast.error(data.msg);
            }

        } catch (error) {
            console.error('Error downloading CSV:', error);
            toast.error("something went wrong");
        }
    };

    const [searchField, setSearchField] = useState("");
    const [searchKeyword, setSearchKeyword] = useState("");

    const handleSearchFilter = (e) => {
        setSearchField(e.target.value);
        if (e.target.value === "") {
            setCurrentPage2(1);
            setUsing(false);
            getLeads();
        }
    }

    const handleChangeLimit = (e) => {
        setLimit(e.target.value);
        setCountUsing(true);
        setCurrentPage2(1);
        if (e.target.value == 20) {
            setCountUsing(false);
        }
        if (using) {
            getSearch(1, e.target.value);
        }


        // getLeads();
    }

    const handleSearchChange = (e) => {
        setSearchKeyword(e.target.value);
        if (e.target.value === "") {
            setCurrentPage2(1);
            setUsing(false);
            getLeads();
        }
    }

    const handleSearchClick = () => {
        setCurrentPage2(1);
        getSearch(1, false);
        if (searchKeyword !== "" && searchField !== "") {
            setUsing(true);
        }
        else {
            toast.warning("please give some input");
        }
    }

    const getSearch = async (pages, updatedLimit) => {
        if (searchField === "" || searchKeyword === "") {
            console.log("no")
            return;
        }

        // let pageLimit = limit;
        // let page = (currentPage - 1) * pageLimit;

        // if (countUsing) {
        //     page = (currentPage2 - 1) * pageLimit;
        // }
        let useLimit = limit;

        if (updatedLimit) {
            useLimit = updatedLimit;
        }

        console.log(useLimit);

        let pageLimit = useLimit;
        let page = (currentPage2 - 1) * pageLimit;

        if (using && pages) {
            page = (pages - 1) * pageLimit;
        }

        try {
            const response = await fetch(`/api/paginate_data/${page}?field=${searchField}&search_keyword=${searchKeyword}&limit=${pageLimit}`)
            const data1 = await response.json();
            console.log(data1);
            if (data1.suc) {
                setData(data1.results);
                setTotalPages2(data1.total_pages);
                setShowingResults(data1.total_rows);
            }
            else {
                setData([]);
                setShowingResults("0");
                toast.error(data.msg);
            }
        }
        catch (error) {
            console.error(error);
            toast.error("something went wrong");
        }

    }

    const handleColumns = () => {
        setCheck(true);
        toggleSidebar();
    }

    const handleFilter = () => {
        setCheck(false);
        toggleSidebar();
    }

    const [handleIt, setHandleIt] = useState(false);
    const [repeat, setRepeat] = useState([0]); // Use an array of unique IDs or indexes

    useEffect(() => {
        getCurrentFilter();
    }, [])

    const getCurrentFilter = async () => {
        const response = await fetch(`/api/get_current_filter`)
        const data = await response.json();
        console.log("data............", data);
        if (data.suc) {
            const length = data.counting;
            let newArr = [];
            for (let i = 0; i < length; i++) {
                newArr.push(i);
            }

            console.log("newArr", length, newArr);
            setRepeat(newArr);
            getLeads();
        }
    }

    // Function to handle changes in handleIt from child
    const newFormData = new FormData();

    const getFilterData = async () => {
        console.log("here is your filter data", [...newFormData]);

        try {
            const response = await fetch("/api/search_with_filter", {
                method: "POST",
                body: newFormData
            })

            const data = await response.json();
            console.log("filter data", data);
            if (data.suc) {
                setData(data.result);
                getLeads();
                toast.success(data.msg);
                toggleSidebar();
            }
            else {
                setData([]);
                setShowingResults("0");
                toggleSidebar();
                toast.error(data.msg);
            }
        }
        catch (error) {
            console.error(error);
            toast.error("something went wrong");
        }

    }


    const onHandleItChange = (fields, success, index, length, isAllSelected, isORSelected) => {
        console.log("11", isAllSelected, isORSelected);
        if (success) {
            for (let i = 0; i < fields.length; i++) {
                newFormData.append(`parent[${index}][filters][${i}][field]`, fields[i].field);
                newFormData.append(`parent[${index}][filters][${i}][operator]`, fields[i].operator);
                newFormData.append(`parent[${index}][filters][${i}][value]`, fields[i].value);
            }

            if (isAllSelected) {
                newFormData.append(`parent[${index}][group_criteria]`, "all");
            }
            else {
                newFormData.append(`parent[${index}][group_criteria]`, "any");
            }

            if (index != 0) {
                if (isORSelected) {
                    newFormData.append(`parent[main_operator_${index}]`, "or");
                }
                else {
                    newFormData.append(`parent[main_operator_${index}]`, "and");
                }
            }
            if (length - 1 == index) {
                getFilterData();
            }
        }
        else {
            toast.error("No Data Found");
        }
    };

    const handleApply2 = () => {
        setHandleIt(true);
        console.log("data", handleIt);
        setCurrentPage2(1);
    };

    const addRepeat = () => {
        setRepeat([...repeat, repeat.length]);
    };


    const removeRepeat = (index) => {
        console.log("removed", index);
        let newRepeat = [...repeat];
        newRepeat.splice(index, 1);
        setRepeat(newRepeat);
        // newRepeat.pop();
        // console.log(newRepeat, [...repeat])
        // setRepeat(newRepeat);
    }

    // const removeRepeat = (index) => {
    //     console.log("removed", index);
    //     setRepeat(prevRepeat => prevRepeat.filter((_, i) => i !== index)); // Remove by index
    // };

    // const addRepeat = () => {
    //     setRepeat([...repeat, <RepeaterField length={repeat.length} handleIt={handleIt} onHandleItChange={onHandleItChange} />]);
    // }

    const handleSubmit = (e) => {
        e.preventDefault();
    }

    // edit

    const [selectedRow, setSelectedRow] = useState(null);

    const handleEditClick = (row) => {
        const encodedData = btoa(row.id);
        navigate(`/student_details/${encodedData}`);

        return;
        setSelectedRow(row);

        const modalId = 'exampleModal';
        const modalElement = document.getElementById(modalId);
        const modalInstance = new window.bootstrap.Modal(modalElement);

        // Add a unique key to the modal to force remount
        modalElement.setAttribute('data-key', Date.now());

        modalInstance.show();
    };

    const changeAlert = () => {
        getLeads();
        // setAlert({ msg: "Details Updated Successfully", type: "success" });

        // setTimeout(() => {
        //     setAlert(null);
        // }, 2000);
    }

    // multiple delete 

    const [checkButton, setCheckButton] = useState(false);

    const handleDelete = () => {
        setCheckButton(true);

        const checkedEmails = Object.keys(checkedRows).filter(index => checkedRows[index]).map(index => data[index].email);

        if (checkedEmails.length > 0) {
            checkedEmails.forEach(email => console.log(email));
        } else {
            toast.warn("No Leads selected.");
            return;
        }

        const modal = new window.bootstrap.Modal(document.getElementById('exampleModal2'));
        modal.show();
    }

    // single delete

    const [deleteRow, setDeleteRow] = useState(null);

    const handleConfirmDelete = async (code, sid) => {

        console.log("sid", sid);

        if (code !== SECRET_CODE) {
            toast.error("Invalid Secret Code");
            return;
        }

        if (checkButton) {
            const checkedIds = Object.keys(checkedRows).filter(index => checkedRows[index]).map(index => data[index].id);

            const checkedEmails = Object.keys(checkedRows).filter(index => checkedRows[index]).map(index => data[index].email);

            let mainId = "";

            if (checkedEmails.length > 0) {
                checkedEmails.forEach(email => console.log(email));
                checkedIds.forEach((id) => {
                    mainId = mainId + "," + id;
                });
                mainId = mainId.slice(1);
                console.log("ids are", mainId)
            }

            const formData = new FormData()
            formData.append("id", mainId);

            try {
                const response = await fetch(`/api/delete`, {
                    method: "POST",
                    body: formData
                });

                const data1 = await response.json();
                console.log(data1);

                if (data1.suc) {
                    toast.success(data1.msg);
                    getLeads();
                }
                else {
                    toast.error(data1.msg);
                }
            }
            catch (error) {
                console.error(error);
                toast.error("something went wrong");
            }

        }
        else {
            console.log(sid);
            const formData = new FormData()
            formData.append("id", sid);

            try {
                const response = await fetch(`/api/delete`, {
                    method: "POST",
                    body: formData
                });

                const data1 = await response.json();
                console.log(data1);

                if (data1.suc) {
                    getLeads();
                    toast.success(data1.msg);
                }
                else {
                    toast.error(data1.msg);
                }
            }
            catch (error) {
                console.error(error);
                toast.error("something went wrong");
            }

        }
    };

    const handleReset = async () => {

        const response = await fetch("/api/field_reset")
        const data = await response.json();
        console.log(data);
        if (data.suc) {
            console.log("yess")
            getLeads();
            getLeadColumns();
            toast.success(data.msg)
        }
        else {
            toast.error(data.msg);
        }
    }

    const handlePageChange = (page) => {
        if (using || countUsing || filterUsing) {
            setCurrentPage2(page);
            if ((countUsing && using) || using) {
                getSearch(page);
            }
        }
        else {
            setCurrentPage(page);
        }
        console.log('Selected Page:', page);
    };


    const [showPages, setShowPages] = useState([30, 40, 50]);

    // if (loading) {
    //     return <div className="loading-spinner">Loading...</div>;
    // }



    const handleReset2 = async () => {
        const response = await fetch(`/api/remove_current_filter`);
        const data = await response.json();
        console.log(data);
        if (data.suc) {
            getLeads();
            toggleSidebar();
            toast.success(data.msg);
        }
    }


    return (
        // d-flex flex-column h-lg-full bg-surface-secondary
        <div>

            <ToastContainer />

            <div className="d-flex flex-column flex-lg-row h-lg-full bg-surface-secondary">
                <Navbar />

                <div className="other-screen flex-grow-1 overflow-y-lg-auto">

                    <main className="p-0 pt-0 bg-surface-secondary" style={{ height: "100vh", overflow: "auto" }}>
                        <div className="card-header mb-1" style={{ position: "sticky", top: "0", zIndex: "4" }}>
                            <div className="d-flex flex-wrap align-items-center justify-content-end">

                                <div className="icons">
                                    <div className="d-flex">
                                        <div className="icon-1 px-2">
                                            <img src={markIcon} alt="" />
                                        </div>
                                        <div className="icon-1 px-2">
                                            <img src={bellIcon} alt="" />
                                        </div>
                                        <div className="icon-1 px-2">
                                            <div className="profile" style={{ borderRadius: "50%", padding: "2px 5px", backgroundColor: "#F0EEEE" }}>
                                                CG
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>



                        <div className="card-header">
                            <div className="d-flex flex-wrap align-items-center justify-content-between text-center">
                                <div className="leads_count mb-3 mb-md-0 text-start">
                                    {using ? <h4>Results : {showingResults}/{totalResults}</h4> :
                                        <h4>Total Leads : {totalResults}</h4>}
                                </div>

                                <div className="icons">
                                    <div className="d-flex flex-wrap">

                                        <div className="icon-1">
                                            <div className="">
                                                {show && <div className={`${term ? 'both' : 'both2'}`}>
                                                    <div className="d-flex">
                                                        <div className="search-input d-flex">
                                                            <select className='my-form-select px-2' value={searchField} onChange={handleSearchFilter}>
                                                                <option selected value="">Search By</option>
                                                                {showSelectedColumns.map((col) => (
                                                                    <option key={col} value={col === "number" ? "phone" : col}>{col}</option>
                                                                ))}
                                                            </select>

                                                            <form onSubmit={handleSubmit}>
                                                                <div className="search-any">
                                                                    <input id="myInput" type="text" className="p-0 px-2" value={searchKeyword} onChange={handleSearchChange} placeholder={searchField === "name" ? "Search Name" : searchField === "email" ? "Search Email" : searchField === "phone" ? "Search Number" : "Search Here"} aria-label="Recipient's username" aria-describedby="button-addon2" />

                                                                    {/* <button onClick={handleSearchClick} className="btn btn-outline-secondary mybtn2" type="submit" id="button-addon2">
                                                                        <img src={searchIcon} alt="" />
                                                                    </button> */}
                                                                    <button className='my-btn3 p-0 px-1' type="submit" onClick={handleSearchClick} id="button-addon2">
                                                                        <i className="bi bi-search"></i>
                                                                    </button>
                                                                </div>
                                                            </form>
                                                        </div>
                                                    </div>
                                                </div>}


                                                {/* <button type="button" onClick={handleClick} data-bs-toggle="tooltip" data-bs-placement="bottom" title='search' className="my-btn3">
                                                    
                                                    {term && <i className="fa-solid fa-xmark"></i>}
                                                    {!term && <img src={searchIcon} alt="" />}
                                                </button> */}
                                                {/* <div className="col-2 col-lg-1 ps-0" >
                                                    <button className='mybtn' onClick={handleClick}>
                                                        {term && <i className="fa-solid fa-xmark"></i>}
                                                        {!term && <i className="bi bi-search"></i>}
                                                    </button>
                                                </div> */}
                                            </div>
                                        </div>

                                        <div className="icon-1 px-1 ps-0">
                                            <button type="button" onClick={handleClick} data-bs-toggle="tooltip" data-bs-placement="bottom" title='search' className={`my-btn3 ${term && `color1`}`} style={{ minWidth: "31px" }}>
                                                {term && <i className="fa-solid fa-xmark"></i>}
                                                {!term && <img src={searchIcon} alt="" />}
                                            </button>
                                        </div>

                                        <div className="icon-1 px-1">
                                            <button type="button" data-bs-toggle="tooltip" data-bs-placement="bottom" onClick={handleDelete} title='delete' className="my-btn3">
                                                <img src={deleteIcon} alt="" />
                                            </button>
                                        </div>

                                        <div className="icon-1 px-1">
                                            <button className="my-btn3" title='customize columns' type="button" data-bs-toggle="tooltip" data-bs-placement="bottom" onClick={handleColumns}>
                                                <img src={columnIcon} alt="" />
                                            </button>
                                        </div>
                                        <div className="icon-1 px-1">
                                            <button className="my-btn3" title='export' data-bs-toggle="tooltip" data-bs-placement="bottom" onClick={handleExport}>
                                                <img src={exportIcon} alt="" />
                                            </button>
                                        </div>
                                        <div className="icon-1 px-1">
                                            <button className="my-btn3" title='filter' data-bs-toggle="tooltip" data-bs-placement="bottom" onClick={handleFilter}>
                                                <img src={filterIcon} alt="" />
                                            </button>
                                        </div>
                                        <div className="icon-1 px-1">
                                            <button className="my-btn3" data-bs-toggle="tooltip" data-bs-placement="bottom" title='import'>
                                                <img src={importIcon} alt="" />
                                            </button>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>

                        <div className="container-fluid px-4">
                            <div className="card shadow border-0 mt-4">
                                <div className="myTable table-responsive">
                                    <table className="table table-hover table-nowrap">
                                        <thead className="thead-light">
                                            <tr>
                                                <th className='selectbox1'><form><input type="checkbox" checked={allChecked} onChange={handleHeaderCheckboxChange} /></form></th>
                                                {showSelectedColumns.map((column) => (
                                                    <th key={column}>{column}</th>
                                                ))}
                                                <th>action</th>
                                            </tr>

                                        </thead>
                                        <tbody id='myTable'>
                                            {data.map((row, index) => (
                                                <tr key={index}>
                                                    <td className='selectbox'><input type="checkbox" checked={checkedRows[index]} onChange={handleRowCheckboxChange(index)} /></td>

                                                    {showSelectedColumns.map((column) => (
                                                        <td key={column}>{
                                                            <button
                                                                type="button"
                                                                className="my-btn3"
                                                                onClick={() => handleEditClick(row)}
                                                            >
                                                                {getColumnValue(row, column)}
                                                            </button>}
                                                        </td>
                                                    ))}

                                                    <td>
                                                        <button
                                                            type="button"
                                                            className="my-btn3"
                                                            onClick={() => handleEditClick(row)}
                                                        >
                                                            <i className="fa-regular fa-pen-to-square"></i>
                                                        </button>
                                                        {/* <button
                                                            type="button"
                                                            className="my-btn3"
                                                            onClick={() => handleDelete1(row)}
                                                        >
                                                            <i className="fa-solid fa-trash"></i>
                                                        </button> */}
                                                    </td>
                                                </tr>
                                            ))}

                                            {data.length === 0 && (<tr>
                                                <td colSpan={showSelectedColumns.length + 2}><h1 className='text-center'>No Data Found</h1></td>
                                            </tr>
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>

                        <section className='py-3 mt-4' style={{ backgroundColor: "white" }}>
                            <div className="my-container px-4">
                                <div className="some d-flex flex-wrap justify-content-center align-items-center" style={{ position: "relative" }}>
                                    <div className="page-list" style={{ zIndex: "1" }}>
                                        {data.length != 0 && <Pagination currentPage={countUsing || using ? currentPage2 : currentPage} totalPages={countUsing || using ? totalPages2 : totalPages} onChange={handlePageChange} />}
                                    </div>

                                    {data.length != 0 && <div className="page-count position-static position-sm-absolute w-100 text-center text-sm-end mt-3 mt-sm-0" style={{ right: "0" }}>
                                        Items per page <select className="my-form-select2" aria-label="Default select example" value={limit} onChange={handleChangeLimit}>
                                            <option selected value={20}>20</option>
                                            {showPages.map((col) => (
                                                <option key={col} value={col}>{col}</option>
                                            ))}
                                        </select>
                                    </div>}
                                </div>

                            </div>


                        </section>

                        <footer className='text-center py-2 px-8'>
                            <p className='px-2'>Copyright © 2024 CGC jhanheri. All Rights Reserved</p>
                        </footer>
                    </main>
                </div>
            </div>

            {shouldRenderSidebar && (
                (check ? <div className="sidebar-overlay">
                    <div className={`sidebar ${isSidebarOpen ? 'open' : 'closed'}`}>
                        <div className="container1">
                            <div className="row sidebar-item1 align-items-center">
                                <div className="col-10">
                                    <h3>Customize Column</h3>
                                </div>
                                <div className="col-2 text-end">
                                    <button className="close-btn" onClick={toggleSidebar}><i className="fa-solid fa-xmark"></i></button>
                                </div>
                            </div>

                            <div className="row sidebar-item2">
                                <div className="col-md-6 col-12 inside-sidebar pt-3 pb-3">
                                    <h5 className=''>Lead Details</h5>

                                    {Object.keys(checkboxState).map((column) => (
                                        <div className="Lead-items d-flex justify-content-between" key={column}>
                                            <div>{column}</div>
                                            <div>
                                                <input
                                                    type="checkbox"
                                                    checked={checkboxState[column]}
                                                    disabled={checkState[column]}
                                                    onChange={(e) => handleCheckboxChange(e, column)}
                                                />
                                            </div>
                                        </div>
                                    ))}

                                </div>
                                <div className="col-md-6 col-12 pt-3 pb-3">
                                    <h5 className='mb-3'>Selected Columns</h5>
                                    <DragDropContext onDragEnd={handleDragEnd}>
                                        <Droppable droppableId="droppable">
                                            {(provided) => (
                                                <div
                                                    ref={provided.innerRef}
                                                    {...provided.droppableProps}
                                                    className="selected-columns"
                                                >
                                                    {selectedColumns.map((column, index) => (
                                                        <Draggable key={column} draggableId={column} index={index}>
                                                            {(provided) => (
                                                                <div className="row mb-3 align-items-center" ref={provided.innerRef}
                                                                    {...provided.draggableProps}
                                                                    {...provided.dragHandleProps}
                                                                    style={{
                                                                        ...provided.draggableProps.style,
                                                                        left: "auto !important",
                                                                        cursor: "grab",
                                                                    }}>
                                                                    <div className="col-1"><i className="bi bi-grip-vertical"></i></div>
                                                                    <div className="col-11">
                                                                        <div className="selected-item">
                                                                            {column}
                                                                            <button className="cross-icon" disabled={checkState[column]} onClick={() => removeColumn(column)}><i className="fa-solid fa-xmark"></i></button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )}
                                                        </Draggable>
                                                    ))}
                                                    {provided.placeholder}
                                                </div>
                                            )}
                                        </Droppable>
                                    </DragDropContext>
                                </div>
                            </div>
                            <div className="row sidebar-item3 align-items-center">
                                <div className="col-6">
                                    <button type="button" onClick={handleReset} className="btn btn-outline-primary">reset</button>
                                </div>
                                <div className="col-6 text-end">
                                    <button type="button" className="btn btn-primary" onClick={handleApply}>Apply</button>
                                </div>
                            </div>
                        </div>

                    </div>
                </div> :
                    <div className="sidebar-overlay">
                        <div className={`sidebar ${isSidebarOpen ? 'open' : 'closed'}`}>
                            <div className="container1">
                                <div className="row sidebar-item1 align-items-center">
                                    <div className="col-10">
                                        <h3>Filter leads by</h3>
                                    </div>
                                    <div className="col-2 text-end">
                                        <button className="close-btn" onClick={toggleSidebar}><i className="fa-solid fa-xmark"></i></button>
                                    </div>
                                </div>

                                <div className="sidebar-item2">

                                    <div className="filter-heading">
                                        <span className='me-3 d-none'>Include activity filter</span>
                                        <span className="form-check form-switch d-none" style={{ display: "inline-block" }}>
                                            <input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault" />
                                        </span>
                                    </div>

                                    <div className="inner-content">
                                        {/* <RepeaterField /> */}

                                        {Object.keys(repeat).map(index => (
                                            <div key={index} style={{ position: "relative" }}>
                                                {index > 0 && (
                                                    <div className="cross">
                                                        <button style={{ backgroundColor: "transparent" }} onClick={() => removeRepeat(index)}>
                                                            <i className="fa-solid fa-xmark"></i>
                                                        </button>
                                                    </div>
                                                )}
                                                <RepeaterField
                                                    length={repeat.length}
                                                    index={index}
                                                    key={index} // Ensure unique key for each RepeaterField
                                                    handleIt={handleIt}
                                                    onHandleItChange={onHandleItChange}
                                                    setHandleIt={setHandleIt}
                                                    showSelectedColumns={showSelectedColumns}
                                                />
                                            </div>
                                        ))}
                                    </div>

                                    <div className="addbtn">

                                        <button onClick={addRepeat} className="mybtn3"><i className="fa-solid fa-plus"></i> Add More</button>
                                    </div>


                                </div>

                                <div className="row sidebar-item3 align-items-center">
                                    <div className="col-6">
                                        <button type="button" onClick={handleReset2} className="btn btn-outline-primary">reset</button>
                                    </div>
                                    <div className="col-6 text-end">
                                        <button type="button" className="btn btn-primary" onClick={handleApply2}>Apply</button>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                ))}

            <div className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title fs-5" id="exampleModalLabel">Edit Student Details</h1>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>

                        {selectedRow && (<Modal changeAlert={changeAlert} selectedRow={selectedRow} setSelectedRow={setSelectedRow} showSelectedColumns={showSelectedColumns} />)}

                    </div>
                </div>
            </div>

            <div className="modal fade" id="exampleModal2" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title fs-5" id="exampleModalLabel">Delete Data</h1>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>

                        <ConfirmModal deleteRow={deleteRow !== null ? deleteRow : "5"} handleConfirmDelete={handleConfirmDelete} />

                    </div>
                </div>
            </div>

        </div>
    )
}

export default Dashboard


