import React, {useEffect} from 'react';

const ProgressBar = ({ formData }) => {
  // Calculate the number of fields filled
  const totalFields = Object.keys(formData).length;
  const filledFields = Object.values(formData).filter(value => value !== null && value !== '').length;
  const progress = (filledFields / totalFields) * 100;

  useEffect(() => {
    console.log("count", totalFields, filledFields)
  }, [])

  // Styles for the progress bar and the completion div
  const progressBarContainer = {
    width: '100%',
    height: '10px',
    backgroundColor: '#e0e0e0',
    borderRadius: '5px',
    position: 'relative',
  };

  const progressBar = {
    width: `${progress}%`,
    height: '100%',
    backgroundColor: '#3b82f6',
    borderRadius: '5px',
  };

  const progressText = {
    position: 'absolute',
    top: '-25px',
    left: `${progress}%`,
    transform: 'translateX(-50%)',
    color: '#3b82f6',
    fontWeight: 'bold',
  };

  return (
    <div style={{ margin: '20px 0' }}>
      <div style={progressBarContainer}>
        <div style={progressBar}></div>
        <div style={progressText}>{Math.round(progress)}%</div>
      </div>
    </div>
  );
};

export default ProgressBar;
