import React, { useState, useRef } from 'react'

const ConfirmModal = ({ handleConfirmDelete, deleteRow}) => {

    const closeRef = useRef(null);

    const [code, setCode] = useState("");

    const deleteData = ()=>{
        let sid = "";
        if(deleteRow.id){
            sid = deleteRow.id;
        }
        else{
            sid = deleteRow;
        }
        // let sid = deleteRow.id || "5";
        // console.log(deleteRow)
        handleConfirmDelete(code, sid);
        closeRef.current.click();
    }

    const handleClick = () => {
        if(code!==""){
            deleteData();
        }
        else{

        }
    }

    const handleClose = ()=>{
        setCode("");
    }

    const handleChange = (e)=>{
        setCode(e.target.value);
    }

    const handleSubmit = (e)=>{
        e.preventDefault();
    }

    return (
        <div>
            <div className='modal-body'>
                <form onSubmit={handleSubmit}>
                    <div className="mb-3">
                        <label htmlFor="name" className="form-label">Enter Secret Code</label>
                        <input
                            type="password"
                            className="form-control"
                            id="code"
                            name="code"
                            value={code}
                            onChange={handleChange}
                            placeholder='Enter Code Here'
                        />
                    </div>
                </form>
            </div>

            <div className="modal-footer">
                <button type="button" ref={closeRef} onClick={handleClose} className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                <button type="button" className="btn btn-danger" onClick={handleClick} >Delete</button>
            </div>
        </div>
    )
}

export default ConfirmModal
