import React, { useEffect, useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Login = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');

    const navigate = useNavigate();

    useEffect(() => {
        if(localStorage.getItem("token")){
            navigate("/");
        }
    }, [])

    // Handler for username input change
    const handleUsernameChange = (event) => {
        setUsername(event.target.value);
    };

    // Handler for password input change
    const handlePasswordChange = (event) => {
        setPassword(event.target.value);
    };

    // Handler for form submit
    const handleSubmit = async (event) => {
        

        event.preventDefault();

        const formData = new FormData();
        formData.append("username",username);
        formData.append("password",password);

        console.log("data is ", [...formData])

        const response = await fetch("/auth/login",{
            method: "POST",
            body: formData
        })

        const data = await response.json();
        console.log("final",data);

        if(data.suc){
            localStorage.setItem("token", data.auth_token);
            if(data.details.role === "3"){
                navigate('/form');
            }
            else{
                navigate('/');
            }
            toast.success("Login Successfully");
        }
        else{
            toast.error("Incorrect Credentials");
        }
    };

    return (
        <div style={styles.container}>
            <ToastContainer />
            <h2>Login</h2>
            <form onSubmit={handleSubmit} style={styles.form}>
                <div style={styles.formGroup}>
                    <label htmlFor="username">Username</label>
                    <input
                        type="text"
                        id="username"
                        value={username}
                        onChange={handleUsernameChange}
                        style={styles.input}
                        required
                    />
                </div>
                <div style={styles.formGroup}>
                    <label htmlFor="password">Password</label>
                    <input
                        type="password"
                        id="password"
                        value={password}
                        onChange={handlePasswordChange}
                        style={styles.input}
                        required
                    />
                </div>
                <Link className='mb-3' to='/forgot_password'>Forgot Password?</Link>
                <button type="submit" style={styles.button}>Login</button>
            </form>
        </div>
    );
}

export default Login


// Inline styles for simplicity
const styles = {
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
        backgroundColor: '#f0f0f0',
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
        width: '300px',
        padding: '20px',
        backgroundColor: 'white',
        borderRadius: '8px',
        boxShadow: '0 0 10px rgba(0,0,0,0.1)',
    },
    formGroup: {
        marginBottom: '15px',
    },
    input: {
        width: '100%',
        padding: '10px',
        border: '1px solid #ccc',
        borderRadius: '4px',
    },
    button: {
        padding: '10px',
        backgroundColor: '#007bff',
        color: 'white',
        border: 'none',
        borderRadius: '4px',
        cursor: 'pointer',
    },
};