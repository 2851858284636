import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Slider from './Slider';
import { ToastContainer, toast } from 'react-toastify';
import ProgressBar from './ProgressBar';

const Payment = () => {
    const navigate = useNavigate();

    const [formData, setFormData] = useState({
        name: '',
        father_name: '',
        today: ''
    });

    const [paymentDetails, setPaymentDetails] = useState({
        amount: '',
        productinfo: '',
        firstname: '',
        email: '',
        phone: ''
    });

    const handleSubmit = async (e) => {
        e.preventDefault();
    };

    const handleLogout = () => {
        localStorage.removeItem('token');
        window.location.href = "/logout";
    };

    useEffect(() => {
        getStudentData();
        getPaymentData();
    }, [])

    const getStudentData = async () => {
        const response = await fetch("/api/fetch_student_application");
        const data1 = await response.json();
        console.log("student data", data1);
        if (data1) {
            setFormData(data1.data[0]);
        }
    }
    const getPaymentData = async () => {
        const response = await fetch("/api/fetch_student_payment_details");
        const data1 = await response.json();
        console.log("student data", data1);
        if (data1.data != '') {
            setPaymentDetails(data1.data);
        } else if (data1.msg != '') {
            toast.error(data1.msg);
        }
    }

    const handleBack = () => {
        navigate('/form/declaration');
    }

    const handlePayment = () => {
        alert("anything");
        navigate('/makepayment');
        return

    };


    return (
        <div>
            <ToastContainer />
            <header>
                <div className="container5 fixed-header py-2 px-3" style={{ boxShadow: "rgb(194 197 201) 0px 4px 20px" }}>
                    <nav>
                        <div className="navbar-header d-flex justify-content-between align-items-center">
                            <Link to='/form'>
                                <img src="https://cdn.npfs.co/uploads/college/image/5f2a94bfe915e036895412_Webp.net_resizeimage.png" style={{ width: "10rem" }} alt="Logo" />
                            </Link>
                            <ul className='m-0 p-0' style={{ listStyle: "none" }}>
                                <li>
                                    <button type='button' className="btn btn-primary" onClick={handleLogout} title='logout'>
                                        <i className="fa-solid fa-arrow-right-from-bracket text-start"></i> Logout
                                    </button>
                                </li>
                            </ul>
                        </div>
                    </nav>
                </div>
            </header>

            <div className="container mb-4" style={{ marginTop: "6.5rem" }}>
                <div>
                    <Slider activeIndex={4} />
                </div>

                <div>
                    <ProgressBar formData={formData} />
                </div>
            </div>


            <div className="container my-5 mt-0">
                <div className="panel" style={{ borderBottom: "unset", borderRight: "unset", borderLeft: "unset" }}>

                    <div className="review d-flex align-items-center mt-3">
                        <div>
                            <button type='button' onClick={handleBack} className='btn btn-outline-secondary me-2' style={{ borderRadius: "unset" }}>BACK</button>
                        </div>
                        <h4 className='mb-0'>Review Your Payment Details</h4>

                    </div>
                    <div className="panel-body">
                        <div className="row">
                            <form onSubmit={handleSubmit}>
                                <div className="row justify-content-between">
                                    <div className="col-lg-7 col-md-6 Payment">
                                        <div className="row flex-column h-100 justify-content-between">
                                            <div className="col-12 py-2" style={{ border: "1px solid gray" }}>
                                                <h5 className='mb-3'>Personal Details</h5>
                                                <p>Name : {paymentDetails.firstname}</p>
                                                <p>Email Id : {paymentDetails.email}</p>
                                                <p>Phone Number : {paymentDetails.phone}</p>
                                            </div>

                                            <div className="col-12 py-2" style={{ border: "1px solid gray" }}>
                                                <h5 className='mb-2' >Order Details</h5>
                                                <div className='row mb-3'>
                                                    <div className="col-7">
                                                        Application Fee(1)
                                                    </div>
                                                    <div className="col-5 text-end"><i class="fa-solid fa-indian-rupee-sign"></i> 30000</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-lg-4 col-md-5">
                                        <div className="row">
                                            <div className="col-12 py-2" style={{ border: "1px solid gray" }}>
                                                <h5 className='mb-3'>Order Details</h5>
                                                <div className='row mb-2'>
                                                    <div className="col-7">Sub Total (1)</div>
                                                    <div className="col-5 text-end"><i class="fa-solid fa-indian-rupee-sign"></i> 30000.00</div>
                                                </div>
                                                <div className='row mb-2 align-items-end'>
                                                    <div className="col-7">Payment Handling Charges</div>
                                                    <div className="col-5 text-end"><i class="fa-solid fa-indian-rupee-sign"></i> 600.00</div>
                                                </div>
                                                <div className='row mb-3'>
                                                    <div className="col-7">Taxes</div>
                                                    <div className="col-5 text-end"><i class="fa-solid fa-indian-rupee-sign"></i> 108.00</div>
                                                </div>
                                                <div className='row mb-3'>
                                                    <div className="col-7">Total</div>
                                                    <div className="col-5 text-end"><i class="fa-solid fa-indian-rupee-sign"></i> 30708.00</div>
                                                </div>

                                                <div className='row mb-3'>
                                                    <div className="col-12">Rs. 500 included for Prospectus Fee</div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-12 text-center">
                                                        <a href='/makepayment' className='btn btn-success me-2' style={{ borderRadius: "unset" }}>Make Payment</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>

            <footer>
                <div className="footer text-end p-3" style={{ backgroundColor: "#E5E5E5" }}>
                    <img src="https://cresttechnosoft.com/wp-content/uploads/2024/08/logo-3.svg" style={{ width: "8rem" }} alt="Footer Logo" />
                </div>
            </footer>
        </div>
    );
};

export default Payment;





























// import React, { useState } from 'react';

// const PayUPayment = () => {
//     const [paymentDetails, setPaymentDetails] = useState({
//         amount: '9999',
//         productinfo: 'Your Product',
//         firstname: 'test',
//         email: 'test@gmail.com',
//         phone: '9896554411'
//     });

// const handleInputChange = (e) => {
//     const { name, value } = e.target;
//     setPaymentDetails({
//         ...paymentDetails,
//         [name]: value,
//     });
// };

//     const handlePayment = async () => {
//         // Send paymentDetails to backend to get hash
//         const response = await fetch('/Payment/generateHash', {
//             method: 'POST',
//             headers: {
//                 'Content-Type': 'application/json',
//             },
//             body: JSON.stringify(paymentDetails),
//         });

//         const result = await response.json();
//         console.log(result);
//         // return;

//         if (result.success) {
//             // Redirect to PayU with form details
//             const payuForm = document.createElement('form');
//             payuForm.setAttribute('action', 'https://test.payu.in/_payment');
//             payuForm.setAttribute('method', 'POST');

//             // Populate the form with required fields
//             Object.keys(result.data).forEach((key) => {
//                 const input = document.createElement('input');
//                 // input.type = 'hidden';
//                 input.name = key;
//                 input.value = result.data[key];
//                 payuForm.appendChild(input);
//             });
//             document.body.appendChild(payuForm);
//             payuForm.submit(); // Submit the form
//         } else {
//             alert('Payment failed!');
//         }
//     };

//     return (
//         <div>
//             <h2>PayU Payment</h2>
//             <input
//                 type="text"
//                 name="firstname"
//                 placeholder="Your Name"
//                 disabled
//                 value={paymentDetails.firstname}
//                 onChange={handleInputChange}
//             />
//             <input
//                 type="text"
//                 name="email"
//                 placeholder="Your Email"
//                 disabled
//                 value={paymentDetails.email}
//                 onChange={handleInputChange}
//             />
//             <input
//                 type="text"
//                 name="phone"
//                 placeholder="Your Phone"
//                 disabled
//                 value={paymentDetails.phone}
//                 onChange={handleInputChange}
//             />
//             <input
//                 type="text"
//                 name="amount"
//                 placeholder="Amount"
//                 disabled
//                 value={paymentDetails.amount}
//                 onChange={handleInputChange}
//             />
//             <button onClick={handlePayment}>Pay Now</button>
//         </div>
//     );
// };

// export default PayUPayment;
