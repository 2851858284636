import React, { useState } from 'react';

const AddNoteModal = () => {
    const [formData, setFormData] = useState({
        followUp: '',
        assignedTo: '',
        createdBy: ''
    });

    // Handle input changes
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log("Form Data Submitted:", formData);
        // Submission logic, e.g., API call
    };

    return (
        <div className="modal-body">
            <form onSubmit={handleSubmit}>
                {/* Follow Up Input */}
                <div className="mb-3">
                    <label htmlFor="followUp" className="form-label">Follow Up</label>
                    <input
                        type="text"
                        className="form-control"
                        id="followUp"
                        name="followUp"
                        value={formData.followUp}
                        onChange={handleChange}
                        placeholder="Enter follow up details"
                    />
                </div>

                {/* Assigned To Input */}
                <div className="mb-3">
                    <label htmlFor="assignedTo" className="form-label">Assigned To</label>
                    <input
                        type="text"
                        className="form-control"
                        id="assignedTo"
                        name="assignedTo"
                        value={formData.assignedTo}
                        onChange={handleChange}
                        placeholder="Enter assignee's name"
                    />
                </div>

                {/* Created By Input */}
                <div className="mb-3">
                    <label htmlFor="createdBy" className="form-label">Created By</label>
                    <input
                        type="text"
                        className="form-control"
                        id="createdBy"
                        name="createdBy"
                        value={formData.createdBy}
                        onChange={handleChange}
                        placeholder="Enter your name"
                    />
                </div>

                {/* Submit Button */}
                <div className="modal-footer p-0">
                    <button type="button" className="btn btn-secondary mb-0" data-bs-dismiss="modal">Close</button>
                    <button type="submit" className="btn btn-primary mb-0">Add Note</button>
                </div>
            </form>
        </div>
    );
};

export default AddNoteModal;
