import { useState } from "react"
import React from 'react'
import AddNoteModal from "./AddNoteModal"

const Followups = () => {
    const [data, setData] = useState([{
        time: "14 sep 2024 05:40 PM",
        title: "Gurkirat Brar recieved email on gurkiratb0729@gmail.com with subject line",
        msg: "Educational Excellence: Guest Lecture's Visit to CGC Jhanjeri."
    }, {
        time: "13 sep 2024 02:10 PM",
        title: "Gurkirat Brar open email on gurkiratb0729@gmail.com with subject line",
        msg: "Experience the Rich Tapestry of Culture at CGC Jhanjeri."
    }, {
        time: "13 sep 2024 12:15 PM",
        title: "Gurkirat Brar recieved email on gurkiratb0729@gmail.com with subject line",
        msg: "Experience the Rich Tapestry of Culture at CGC Jhanjeri."
    }])

    const handleAddFollowUp = ()=>{
        const modal = new window.bootstrap.Modal(document.getElementById('exampleModal3'));
        modal.show();
    }

    return (
        <div>
            <div className="values px-4 py-3">

                <div className="d-flex justify-content-between">
                    <div className="">
                        <h4>Follow Up & Notes</h4>
                    </div>

                    <div>
                        <button type="button" onClick={handleAddFollowUp} class="btn btn-outline-primary px-2 py-1">Add Follow Up</button>
                    </div>
                </div>
                <hr className="mt-2" />

                <div className="timelines d-flex flex-column">
                    {data.map((element, index) => (
                        <div key={index} className='d-flex mb-5'>
                            <div className="col-2" style={{ width: "90px" }}>
                                <div className="time">{element.time}</div>

                            </div>
                            <div className="px-4">
                                <div className="icon">
                                    <div className='per-icon highlight' style={{ borderColor: "#007bff" }}><i class="fa-regular fa-envelope"></i></div>
                                </div>
                            </div>
                            <div className="nothing" style={{ width: "100%" }}>
                                <div className="msg">
                                    <div className="inner-msg">
                                        <span style={{ fontWeight: "500" }}>Follow Up</span> - <span className=''>with Gurkirat Brar</span>
                                        <br />
                                        <span style={{ fontWeight: "500" }}>Assigned To</span>: <span className=''>Kajal J3220</span> | <span style={{ fontWeight: "500" }}>Due</span>: <span className=''>15 sep 05:15 PM</span>
                                        <br />
                                        <span style={{ fontWeight: "500" }}>Created By</span>: <span className=''>Kajal J3220</span> | <span style={{ fontWeight: "500" }}>Status</span>: <span style={{ color: "green" }}>Completed</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>

            </div>





            <div className="modal fade" id="exampleModal3" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title fs-5" id="exampleModalLabel">Add Follow Up</h1>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>

                        <AddNoteModal />

                    </div>
                </div>
            </div>
        </div>
    )
}

export default Followups
