import React from 'react';
import { useState, useEffect } from 'react';
import Timeline from './Timeline';

const CommunicationLogs = ({ id }) => {
    const [data, setData] = useState([
        {
            type: 'Email Summary',
            details: { sent: 100, delivered: 95, open_rate: '80%', click_rate: '40%' },
        },
        {
            type: 'SMS Summary',
            details: { sent: 50, delivered: 45, open_rate: '60%', click_rate: '20%' },
        },
        {
            type: 'WhatsApp Summary',
            details: { sent: 200, delivered: 180, open_rate: '90%', click_rate: '50%' },
        },
    ]);

    useEffect(() => {
        getCommunicationLogs();
    }, [])

    const getCommunicationLogs = async () => {
        const response = await fetch(`/api/get_communication_logs/${id}`)
        const data1 = await response.json();
        console.log(data1);
        if (data1.suc || true) {
            setData(data1);
        }
    }

    const borderColors = ['green', 'purple', 'royalblue', 'red'];

    const [timelines, setTimelines] = useState([{
        time: "14 sep 2024 05:40 PM",
        title: "Gurkirat Brar recieved email on gurkiratb0729@gmail.com with subject line",
        msg: "Educational Excellence: Guest Lecture's Visit to CGC Jhanjeri.",
        open_at: "0",
        score: "0"
    }, {
        time: "13 sep 2024 02:10 PM",
        title: "Gurkirat Brar opened email on gurkiratb0729@gmail.com with subject line",
        msg: "Experience the Rich Tapestry of Culture at CGC Jhanjeri.",
        open_at: "1",
        score: "+1"
    }, {
        time: "13 sep 2024 12:15 PM",
        title: "Gurkirat Brar recieved email on gurkiratb0729@gmail.com with subject line",
        msg: "Experience the Rich Tapestry of Culture at CGC Jhanjeri.",
        open_at: "0",
        score: "0"
    }])

    useEffect(() => {
        getTimeline();
    }, [])

    const getTimeline = async () => {
        const response = await fetch(`/api/lead_timeline/${id}`)
        const data = await response.json();
        console.log(data);
        if (data.suc) {
            setTimelines(data.leadActivity);
        }
    }

    return (
        <div>
            <div className="values px-4 py-3">
                <span>
                    <h4>Communication Logs</h4>
                </span>
                <hr />
                <div className="communication-logs">
                    {data.map((log, index) => (
                        <div key={index} className="row mb-5">
                            <div className="col-12 mb-2">
                                <h4>{log.type}</h4>
                            </div>
                            {Object.entries(log.details).map(([key, value], idx) => (
                                <div key={idx} className="col-2">
                                    <div className="log-details text-center" style={{ borderBottom: `2px solid ${borderColors[idx % borderColors.length]}` }}>
                                        <h3>{value}</h3>
                                        <h5>{key.replace('_', ' ')}</h5> {/* Makes the key readable */}
                                    </div>
                                </div>
                            ))}
                        </div>
                    ))}

                    <div className="timelines d-flex flex-column">
                        <div className='mb-4'>
                            <h4 >Communication Log</h4>
                        </div>
                        <Timeline id={id} dataOnly={true}/>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CommunicationLogs;
