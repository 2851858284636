import React from 'react'

const DocumentLocker = () => {
    return (
        <div>
            <div className="values px-4 py-3">
                <span>
                    <h4>Document Locker</h4>
                </span>
                <hr />
            </div>
        </div>
    )
}

export default DocumentLocker
