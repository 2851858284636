import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Slider from './Slider';
import { ToastContainer, toast } from 'react-toastify';
import ProgressBar from './ProgressBar';

const Documents = () => {
    const navigate = useNavigate();

    const [formData, setFormData] = useState({
        photo: '',
        sign: '',
        parentSign: '',
        xMarksheet: '',
        aadharCard: '',
    });

    const handleSubmit = async (e) => {
        e.preventDefault();

        const totalFields = Object.keys(formData).length;
        const filledFields = Object.values(formData).filter(value => value !== null && value !== '').length;
        const progress = (filledFields / totalFields) * 100;
        const progress2 = Math.round(progress);
        console.log("field counts", totalFields, filledFields, progress);

        console.log(photo, sign, parentSign, xMarksheet, aadharCard);

        if(formData.photo !== "" || formData.photo !== "" || formData.photo !== "" || formData.photo !== "" || formData.photo !== "" ){
            navigate('/form/declaration');
            return;
        }


        const newFormData = new FormData()
        newFormData.append('photo', photo);
        newFormData.append('sign', sign);
        newFormData.append('parentSign', parentSign);
        newFormData.append('xMarksheet', xMarksheet);
        newFormData.append('aadharCard', aadharCard);

        console.log([...newFormData]);

        const response = await fetch(`/api/upload_docs?validate=validate_form&step=3&completion_status=${progress2}`, {
            method: "POST",
            body: newFormData
        });
        const data = await response.json();
        if (data.suc) {
            toast.success(data.msg);
            // navigate('/form/declaration');
        }
        else {
            toast.error(data.msg);
        }
    };

    const handleSaveData = async () => {
        const totalFields = Object.keys(formData).length;
        const filledFields = Object.values(formData).filter(value => value !== null && value !== '').length;
        const progress = (filledFields / totalFields) * 100;
        const progress2 = Math.round(progress);
        console.log("field counts", totalFields, filledFields, progress);
        
        console.log(photo, sign, parentSign, xMarksheet, aadharCard);
        
        const newFormData = new FormData();
        newFormData.append('photo', photo);
        newFormData.append('sign', sign);
        newFormData.append('parentSign', parentSign);
        newFormData.append('xMarksheet', xMarksheet);
        newFormData.append('aadharCard', aadharCard);

        console.log([...newFormData]);
        const response = await fetch(`/api/upload_docs?completion_status=${progress2}`, {
            method: "POST",
            body: newFormData
        });
        const data = await response.json();
        if (data.suc) {
            toast.success(data.msg);
            // linear-gradient(to right, #e0e5e9, #a1c4fd, #5a7bdf)
            // setPhoto(true);
            // setSign(null);
            // setParentSign(null);
            // setXMarksheet(null);
            // setAadharCard(null);
        }
        else {
            toast.error(data.msg);
        }
    };

    const handleLogout = () => {
        localStorage.removeItem('token');
        window.location.href = "/logout";
    };

    const [photo, setPhoto] = useState(null);
    const [sign, setSign] = useState(null);
    const [parentSign, setParentSign] = useState(null);
    const [xMarksheet, setXMarksheet] = useState(null);
    const [aadharCard, setAadharCard] = useState(null);

    const handleChange1 = (e) => {
        setPhoto(e.target.files[0]);
    }

    const handleChange2 = (e) => {
        setSign(e.target.files[0]);
    }

    const handleChange3 = (e) => {
        setParentSign(e.target.files[0]);
    }

    const handleChange4 = (e) => {
        setXMarksheet(e.target.files[0]);
    }

    const handleChange5 = (e) => {
        setAadharCard(e.target.files[0]);
    }

    useEffect(() => {
        getStudentData();
    }, [])

    const getStudentData = async () => {
        const response = await fetch("/api/fetch_student_application");
        const data1 = await response.json();
        console.log("student data", data1);
        if (data1) {
            setFormData(data1.data[0]);
        }
    }

    const handleBack = () => {
        navigate('/form/educational_details');
    }

    return (
        <div>
            <ToastContainer />
            <header>
                <div className="container5 fixed-header py-2 px-3" style={{ boxShadow: "rgb(194 197 201) 0px 4px 20px" }}>
                    <nav>
                        <div className="navbar-header d-flex justify-content-between align-items-center">
                            <Link to='/form'>
                                <img src="https://cdn.npfs.co/uploads/college/image/5f2a94bfe915e036895412_Webp.net_resizeimage.png" style={{ width: "10rem" }} alt="Logo" />
                            </Link>
                            <ul className='m-0 p-0' style={{ listStyle: "none" }}>
                                <li>
                                    <button type='button' className="btn btn-primary" onClick={handleLogout} title='logout'>
                                        <i className="fa-solid fa-arrow-right-from-bracket text-start"></i> Logout
                                    </button>
                                </li>
                            </ul>
                        </div>
                    </nav>
                </div>
            </header>

            <div className="container mb-4" style={{ marginTop: "6.5rem" }}>
                <div>
                    <Slider activeIndex={2} />
                </div>

                <div>
                    <ProgressBar formData={formData} />
                </div>
            </div>

            <div className="container my-5 mt-0">
                <div className="panel">
                    <div className="panel-body">
                        <div className="row">
                            <form onSubmit={handleSubmit}>
                                <div className="row">

                                    <div className="col-md-6 mb-md-4 mb-2">
                                        <div className="form-group">
                                            <label className="form-label" htmlFor="photo">Upload Passport Size Photo <span className='star'>*</span></label>
                                            <input type="file" required disabled={formData.photo !== '' || photo === true} onChange={handleChange1} id='photo' name='photo' className='form-input' />
                                            {formData.photo === '' ? <label className="form-label faded mt-1">Max Upload Limit 2 MB (only jpeg, jpg and png allowed)</label> : <label className="form-label faded mt-1">file already uploaded</label>}
                                        </div>
                                    </div>

                                    <div className="col-md-6 mb-md-4 mb-2">
                                        <div className="form-group">
                                            <label className="form-label" htmlFor="sign">Upload Student Signature <span className='star'>*</span></label>
                                            <input type="file" required disabled={formData.sign !== ''} onChange={handleChange2} id='sign' name='sign' className='form-input' />
                                            {formData.sign === '' ? <label className="form-label faded mt-1">Max Upload Limit 2 MB (only jpeg, jpg and png allowed)</label> : <label className="form-label faded mt-1">file already uploaded</label>}
                                        </div>
                                    </div>

                                    <div className="col-md-6 mb-md-4 mb-2">
                                        <div className="form-group">
                                            <label className="form-label" htmlFor="parent_sign">Upload Parent Name & Signature <span className='star'>*</span></label>
                                            <input type="file" required disabled={formData.parentSign !== ''} onChange={handleChange3} id='parent_sign' name='parent_sign' className='form-input' />
                                            {formData.parentSign === '' ? <label className="form-label faded mt-1">Max Upload Limit 2 MB (only jpeg, jpg and png allowed)</label> : <label className="form-label faded mt-1">file already uploaded</label>}
                                        </div>
                                    </div>

                                    <div className="col-md-6 mb-md-4 mb-2">
                                        <div className="form-group">
                                            <label className="form-label" htmlFor="x_marksheet">Upload 10th Marksheet <span className='star'>*</span></label>
                                            <input type="file" required disabled={formData.xMarksheet !== ''} onChange={handleChange4} id='10th_marksheet' name='10th_marksheet' className='form-input' />
                                            {formData.xMarksheet === '' ? <label className="form-label faded mt-1">Max Upload Limit 2 MB (only jpeg, jpg and png allowed)</label> : <label className="form-label faded mt-1">file already uploaded</label>}
                                        </div>
                                    </div>

                                    <div className="col-md-6 mb-md-4 mb-2">
                                        <div className="form-group">
                                            <label className="form-label" htmlFor="aadhar_card">Upload Aadhaar Card <span className='star'>*</span></label>
                                            <input type="file" required disabled={formData.aadharCard !== ''} onChange={handleChange5} id='aadhar_card' name='aadhar_card' className='form-input' />
                                            {formData.aadharCard === '' ? <label className="form-label faded mt-1">Max Upload Limit 2 MB (only jpeg, jpg and png allowed)</label> : <label className="form-label faded mt-1">file already uploaded</label>}
                                        </div>
                                    </div>

                                </div>

                                <div className='d-none d-sm-block'>
                                    <div className="row align-items-center">
                                        <div className="col-6">
                                            <button type='button' onClick={handleBack} className='btn btn-secondary me-2' style={{ borderRadius: "unset" }}>BACK</button>
                                            <button type='button' onClick={handleSaveData} className='btn btn-secondary' style={{ borderRadius: "unset" }}>SAVE & EXIT</button>
                                        </div>
                                        <div className="col-6 text-end">
                                            <button type='submit' className='btn btn-primary' style={{ borderRadius: "unset" }}>NEXT</button>
                                        </div>
                                    </div>
                                </div>

                                <div className='d-block d-sm-none'>
                                    <div className="row align-items-center justify-content-between">
                                        <div className="col-3">
                                            <button type='button' onClick={handleBack} className='btn btn-success' style={{ borderRadius: "50%" }}><i class="fa-solid fa-chevron-left"></i></button>
                                        </div>
                                        <div className="col-6 text-center">Step 3 of 5</div>
                                        <div className="col-3 text-end">
                                            <button type='submit' className='btn btn-success' style={{ borderRadius: "50%" }}><i class="fa-solid fa-chevron-right"></i></button>
                                        </div>
                                        <div className="col-12 mt-3">
                                            <button type='button' onClick={handleSaveData} className='btn btn-outline-secondary' style={{ borderRadius: "unset", width: "100%" }}>SAVE & EXIT</button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>

            <footer>
                <div className="footer text-end p-3" style={{ backgroundColor: "#E5E5E5" }}>
                    <img src="https://cresttechnosoft.com/wp-content/uploads/2024/08/logo-3.svg" style={{ width: "8rem" }} alt="Footer Logo" />
                </div>
            </footer>
        </div>
    );
};

export default Documents;
