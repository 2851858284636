// Loader.js
import React, { useEffect, useState } from 'react';
import styled, { keyframes } from 'styled-components';

// Keyframes for the background animation
const shimmer = keyframes`
  0% { background-position: -150% 0; }
  100% { background-position: 150% 0; }
`;

// Styled components
const LoaderContainer = styled.div`
  height: 100vh;
  width: 100%;
  display: grid;
  place-items: center;
  font-family: 'Poppins', sans-serif;
  background-color: #f0f0f0;
  overflow: hidden;
`;

const Card = styled.div`
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  overflow: hidden;
  width: 400px;
  background-color: #ffffff;
`;

const CardHeader = styled.div`
  height: 180px;
  background-image: linear-gradient(to right, #f0f0f0 0%, #e0e0e0 20%, #f0f0f0 40%);
  background-size: 200% 100%;
  animation: ${shimmer} 1.2s linear infinite;
`;

const CardContent = styled.div`
  padding: 20px;
`;

const CardTitle = styled.div`
  height: 24px;
  margin-bottom: 16px;
  background-image: linear-gradient(to right, #f0f0f0 0%, #e0e0e0 20%, #f0f0f0 40%);
  background-size: 200% 100%;
  animation: ${shimmer} 1.2s linear infinite;
  border-radius: 4px;
`;

const CardExcerpt = styled.div`
  margin-bottom: 20px;
  span {
    display: block;
    height: 16px;
    margin-bottom: 10px;
    background-image: linear-gradient(to right, #f0f0f0 0%, #e0e0e0 20%, #f0f0f0 40%);
    background-size: 200% 100%;
    animation: ${shimmer} 1.2s linear infinite;
    border-radius: 4px;
  }

  span:last-child {
    width: 80%;
  }
`;

const Author = styled.div`
  display: flex;
  align-items: center;
`;

const ProfileImg = styled.div`
  border-radius: 50%;
  width: 50px;
  height: 50px;
  background-image: linear-gradient(to right, #f0f0f0 0%, #e0e0e0 20%, #f0f0f0 40%);
  background-size: 200% 100%;
  animation: ${shimmer} 1.2s linear infinite;
`;

const AuthorInfo = styled.div`
  margin-left: 12px;
  width: 150px;
`;

const AuthorName = styled.div`
  height: 16px;
  margin-bottom: 8px;
  background-image: linear-gradient(to right, #f0f0f0 0%, #e0e0e0 20%, #f0f0f0 40%);
  background-size: 200% 100%;
  animation: ${shimmer} 1.2s linear infinite;
  border-radius: 4px;
`;

const AuthorDate = styled.div`
  height: 14px;
  background-image: linear-gradient(to right, #f0f0f0 0%, #e0e0e0 20%, #f0f0f0 40%);
  background-size: 200% 100%;
  animation: ${shimmer} 1.2s linear infinite;
  border-radius: 4px;
`;

// Main Component
const Loader = () => {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => setLoaded(true), 10000000);
    return () => clearTimeout(timer);
  }, []);

  if (loaded) {
    return null; // or redirect to the main content
  }

  return (
    <LoaderContainer>
      <Card>
        <CardHeader></CardHeader>
        <CardContent>
          <CardTitle></CardTitle>
          <CardExcerpt>
            <span></span>
            <span></span>
            <span></span>
          </CardExcerpt>
          <Author>
            <ProfileImg></ProfileImg>
            <AuthorInfo>
              <AuthorName></AuthorName>
              <AuthorDate></AuthorDate>
            </AuthorInfo>
          </Author>
        </CardContent>
      </Card>
    </LoaderContainer>
  );
};

export default Loader;
