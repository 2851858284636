import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
// import './Slider.css'; 

const Slider = ({activeIndex}) => {
    const items = ['h1', 'h2', 'h3', 'h4', 'h5']; // Example items
    const itemsPerPage = 4; // Number of items to show per page
    const [currentIndex, setCurrentIndex] = useState(0);

    const totalItems = items.length;
    const totalPages = Math.ceil(totalItems / itemsPerPage);
    const [value, setValue] = useState(totalItems - itemsPerPage);

    const [showMe, setShowMe] = useState(4);

    const updateValue = () => {
        if (value >= itemsPerPage) {
            setShowMe(4);
            setValue(value - itemsPerPage);
        }
        else {
            setShowMe(value);
        }
    }

    const handleNext = () => {
        if (currentIndex < totalPages - 1) {
            setCurrentIndex(currentIndex + 1);
            updateValue();
        }
    };

    const handlePrevious = () => {
        if (currentIndex > 0) {
            setCurrentIndex(currentIndex - 1);
        }
    };

    const startIndex = currentIndex * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;

    return (
        <section>
            <div className="slider">
                <div className="mline d-block d-md-none" style={{
                    height:"2px",
                    width:"100%",
                    backgroundColor:"rgba(var(--bs-link-color-rgb), var(--bs-link-opacity, 1))",
                    position:"absolute",
                    top:"50%",
                    zIndex:"-1",
                }}></div> 
                <div
                    className="d-flex myscroller"
                    style={{
                        // transform: `translateX(-${currentIndex == 0 ? 0 : ((currentIndex-1) * 100) + (25 * showMe)}%)`,
                        // transform: `translateX(-${currentIndex * (100 / 3)}%)`,
                        // transition: 'transform 0.2s ease',
                        overflow: 'auto'
                    }}
                >
                    <div className="item">
                        <Link to='/form' className={`circle ${activeIndex === 0 ? 'active' : ''}`}>
                            <i className="fa-solid fa-user"></i>
                        </Link>
                        <h6 className='d-none d-md-block'>Course and Personal Details</h6>
                    </div>

                    <div className="item">
                        <Link to='/form/educational_details' className={`circle ${activeIndex === 1 ? 'active' : ''}`}>
                            <i className="fa-solid fa-file-lines"></i>
                        </Link>
                        <h6 className='d-none d-md-block'>Educational Qualification Details</h6>
                    </div>

                    <div className="item">
                        <Link to='/form/documents' className={`circle ${activeIndex === 2 ? 'active' : ''}`}>
                            <i className="fa-solid fa-paperclip"></i>
                        </Link>
                        <h6 className='d-none d-md-block'>Upload Documents</h6>
                    </div>

                    <div className="item">
                        <Link to='/form/declaration' className={`circle ${activeIndex === 3 ? 'active' : ''}`}>
                            <i className="fa-regular fa-square-check"></i>
                        </Link>
                        <h6 className='d-none d-md-block'>Declaration</h6>
                    </div>

                    <div className="item">
                        <Link to='/form/payment' className={`circle ${activeIndex === 4 ? 'active' : ''}`}>
                            <i className="fa-regular fa-credit-card"></i>
                        </Link>
                        <h6 className='d-none d-md-block'>Payment</h6>
                    </div>
                </div>
                {/* <button
                    className="arrow-button prev d-md-none"
                    onClick={handlePrevious}
                    style={{ display: currentIndex === 0 ? 'none' : 'block' }}
                >
                    <i className="fa-solid fa-chevron-left"></i>
                </button>
                <button
                    className="arrow-button next d-md-none"
                    onClick={handleNext}
                    style={{ display: currentIndex === totalPages - 1 ? 'none' : 'block' }}
                >
                    <i className="fa-solid fa-chevron-right"></i>
                </button> */}
            </div>
        </section>
    );
};

export default Slider;
